import React, { useState, useEffect } from "react";
import { Form, Image } from "react-bootstrap";
import { Card, CardBody } from "./../../components/card/card.jsx";
import Select from "react-select";
import { dateOptions } from "./../../components/selectvalues/selectvalue.jsx";
import glassshimmer from "../../assets/glassshimmer.png";
import DatePicker from "react-datepicker";
import Pagination from "./../../components/pagination/Pagination.jsx"; // Import the Pagination component

const Ingredient = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [currentTab, setCurrentTab] = useState("viewed");
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(""); // Add search term state
  const [selectedTable, setSelectedTable] = useState(0); // Default to table 0

  var currentDate = new Date();
  // Convert the current date to a timestamp in seconds
  var timestampInSeconds = Math.floor(currentDate.getTime() / 1000);

  const [IngredientViewTile, setIngredientViewTile] = useState([]); //Ingredients Page View
  const [IngredientClickedTile, setIngredientClickedTile] = useState([]); //Ingredients Click
  const [IngredientCraftedTile, setIngredientCraftedTile] = useState([]); //Ingredients Crafted
  const [IngredientLikeTile, setIngredientLikeTile] = useState([]); //Ingredients Like
  const [IngredientSharedTile, setIngredientSharedTile] = useState([]); //Ingredients Shared

  const [ingredientselectedDateOption, setIngredientSelectedDateOption] =
    useState(dateOptions[1]);
  const [ingredientcustomDateRange, setIngredientCustomDateRange] =
    useState(false);
  const [ingredientstartDate, setIngredientStartDate] = useState(new Date());
  const [ingredientendDate, setIngredientEndDate] = useState(new Date());

  // Logic to get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Filter items based on search term
  const getFilteredItems = (items) => {
    return items.filter((item) =>
      item.details.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const IngredientviewcurrentItems = getFilteredItems(IngredientViewTile).slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const IngredientclickedcurrentItems = getFilteredItems(IngredientClickedTile).slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const IngredientcraftedcurrentItems = getFilteredItems(IngredientCraftedTile).slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const IngredientlikecurrentItems = getFilteredItems(IngredientLikeTile).slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const IngredientsharedcurrentItems = getFilteredItems(IngredientSharedTile).slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const producttabs = [
    { id: "viewed", label: "Viewed", active: true },
    { id: "clicked", label: "Clicked", active: false },
    { id: "crafted", label: "Crafted", active: false },
    { id: "liked", label: "Liked", active: false },
    { id: "shared", label: "Shared", active: false },
  ];

  const tabContent = [
    <div className="table-responsive ">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : IngredientviewcurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        IngredientviewcurrentItems.map((product, index) => (
          <div key={index} className={"mb-3 p-3 product_border"}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <Image
                      src={`${
                        product.details.baseSponsorImage
                          ? product.details.baseSponsorImage
                          : glassshimmer
                      }`}
                      className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-80px"
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">
                      {product.details.name}
                    </div>
                  </div>
                  <div className="flex-1 ms-4">
                    <label>Views:</label>
                    <span className="ms-3" style={{ color: "#3CD25D" }}>
                      {product.viewCount}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div
                  className={index < IngredientViewTile.length - 1 ? "mb-2" : ""}
                >
                  <div className="row">
                    <div className="col-xl-12 col-12">
                      <div>
                        <label>Top Cocktails:</label>
                        <span className="ms-4">{product.qty}</span>
                      </div>
                      <div>
                        <label>Top Mixer:</label>
                        <span className="ms-4">{product.revenue}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
    <div className="table-responsive ">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : IngredientclickedcurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        IngredientclickedcurrentItems.map((product, index) => (
          <div key={index} className={"mb-3 p-3 product_border"}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <Image
                      src={`${
                        product.details.baseSponsorImage
                          ? product.details.baseSponsorImage
                          : glassshimmer
                      }`}
                      className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-80px"
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">
                      {product.details.name}
                    </div>
                  </div>
                  <div className="flex-1 ms-4">
                    <label>Clicks:</label>
                    <span className="ms-3" style={{ color: "#3cd2a5" }}>
                      {product.count}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div
                  className={
                    index < IngredientClickedTile.length - 1 ? "mb-2" : ""
                  }
                >
                  <div className="row">
                    <div className="col-xl-12 col-12">
                      <div>
                        <label>Top Cocktails:</label>
                        <span className="ms-4">{product.qty}</span>
                      </div>
                      <div>
                        <label>Top Mixer:</label>
                        <span className="ms-4">{product.revenue}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
    <div className="table-responsive ">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : IngredientcraftedcurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        IngredientcraftedcurrentItems.map((product, index) => (
          <div key={index} className={"mb-3 p-3 product_border"}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <Image
                      src={`${
                        product.details.baseSponsorImage
                          ? product.details.baseSponsorImage
                          : glassshimmer
                      }`}
                      className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-80px"
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">
                      {product.details.name}
                    </div>
                  </div>
                  <div className="flex-1 ms-4">
                    <label>Crafted:</label>
                    <span className="ms-3" style={{ color: "#3cd2a5" }}>
                      {product.count}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div
                  className={
                    index < IngredientCraftedTile.length - 1 ? "mb-2" : ""
                  }
                >
                  <div className="row">
                    <div className="col-xl-12 col-12">
                      <div>
                        <label>Top Cocktails:</label>
                        <span className="ms-4">{product.qty}</span>
                      </div>
                      <div>
                        <label>Top Mixer:</label>
                        <span className="ms-4">{product.revenue}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
    <div className="table-responsive ">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : IngredientlikecurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        IngredientlikecurrentItems.map((product, index) => (
          <div key={index} className={"mb-3 p-3 product_border"}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <Image
                      src={`${
                        product.details.baseSponsorImage
                          ? product.details.baseSponsorImage
                          : glassshimmer
                      }`}
                      className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-80px"
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">
                      {product.details.name}
                    </div>
                  </div>
                  <div className="flex-1 ms-4">
                    <label>Likes:</label>
                    <span className="ms-3" style={{ color: "#3cd2a5" }}>
                      {product.count}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div
                  className={
                    index < IngredientLikeTile.length - 1 ? "mb-2" : ""
                  }
                >
                  <div className="row">
                    <div className="col-xl-12 col-12">
                      <div>
                        <label>Top Cocktails:</label>
                        <span className="ms-4">{product.qty}</span>
                      </div>
                      <div>
                        <label>Top Mixer:</label>
                        <span className="ms-4">{product.revenue}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
    <div className="table-responsive ">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : IngredientsharedcurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        IngredientsharedcurrentItems.map((product, index) => (
          <div key={index} className={"mb-3 p-3 product_border"}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <Image
                      src={`${
                        product.details.baseSponsorImage
                          ? product.details.baseSponsorImage
                          : glassshimmer
                      }`}
                      className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-80px"
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">
                      {product.details.name}
                    </div>
                  </div>
                  <div className="flex-1 ms-4">
                    <label>Shares:</label>
                    <span className="ms-3" style={{ color: "#3cd2a5" }}>
                      {product.qty}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div
                  className={
                    index < IngredientSharedTile.length - 1 ? "mb-2" : ""
                  }
                >
                  <div className="row">
                    <div className="col-xl-12 col-12">
                      <div>
                        <label>Top Cocktails:</label>
                        <span className="ms-4">{product.qty}</span>
                      </div>
                      <div>
                        <label>Top Mixer:</label>
                        <span className="ms-4">{product.revenue}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
  ];

  useEffect(() => {
    const endTimestamp = timestampInSeconds;
    const startTimestamp = endTimestamp - 7 * 24 * 60 * 60; // 7 days in seconds
    const defaultSelectedOption = dateOptions[1]; // Weekly by default

    setIngredientSelectedDateOption(defaultSelectedOption);
    setIngredientStartDate(new Date(startTimestamp * 1000)); // Convert seconds to milliseconds
    setIngredientEndDate(new Date(endTimestamp * 1000)); // Convert seconds to milliseconds

    IngredientTileInfo(
      getFilterValue(currentTab),
      startTimestamp,
      endTimestamp
    );
  }, [currentTab]);

  const dateToTimestampInSeconds = (date) => {
    return Math.floor(date.getTime() / 1000);
  };

  const handleDateOptionChangeIngredient = (selectedOption) => {
    setIngredientSelectedDateOption(selectedOption);
    if (selectedOption.value === 3) {
      setIngredientCustomDateRange(true);
    } else {
      setIngredientCustomDateRange(false);
      const endTimestamp = timestampInSeconds;
      let startTimestamp;
      switch (selectedOption.value) {
        case 5: // Daily
          startTimestamp = endTimestamp - 24 * 60 * 60; // 1 day in seconds
          break;
        case 2: // Weekly
          startTimestamp = endTimestamp - 7 * 24 * 60 * 60; // 7 days in seconds
          break;
        case 1: // Monthly
          // Calculate start time for the beginning of the current month
          const currentDate = new Date();
          startTimestamp =
            new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              1
            ).getTime() / 1000;
          break;
        case 0: // Yearly
          // Calculate start time for the beginning of the current year
          startTimestamp =
            new Date(new Date().getFullYear(), 0, 1).getTime() / 1000;
          break;
        default:
          // Custom date range
          return;
      }

      setIngredientStartDate(new Date(startTimestamp * 1000)); // Convert seconds to milliseconds
      setIngredientEndDate(new Date(endTimestamp * 1000)); // Convert seconds to milliseconds
      IngredientTileInfo(
        getFilterValue(currentTab),
        startTimestamp,
        endTimestamp
      );
    }
  };

  const handleCustomDateRangeChange = (startDate, endDate) => {
    const startTimestamp = dateToTimestampInSeconds(startDate);
    const endTimestamp = dateToTimestampInSeconds(endDate);
    IngredientTileInfo(
      getFilterValue(currentTab),
      startTimestamp,
      endTimestamp
    );
  };

  const IngredientTileInfo = async (
    filterValue,
    startTime = null,
    endTime = null,
    table = 0
  ) => {
    setIsLoading(true);
    try {
      if (!startTime || !endTime) {
        // Custom Date Range
        startTime = dateToTimestampInSeconds(ingredientstartDate);
        endTime = dateToTimestampInSeconds(ingredientendDate);
      }
      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/ingredientpageinfo?startTime=${startTime}&endTime=${endTime}&filter=${filterValue}&table=${table}`
      );
      const result = await response.json();
      switch (filterValue) {
        case 0:
          setIngredientViewTile(result);
          break;
        case 1:
          setIngredientClickedTile(result);
          break;
        case 2:
          setIngredientCraftedTile(result);
          break;
        case 3:
          setIngredientLikeTile(result);
          break;
        case 4:
          setIngredientSharedTile(result);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Ensures loading is turned off whether fetch succeeds or fails
    }
  };

  const getFilterValue = (tab) => {
    switch (tab) {
      case "viewed":
        return 0;
      case "clicked":
        return 1;
      case "crafted":
        return 2;
      case "liked":
        return 3;
      case "shared":
        return 4;
      default:
        return 0;
    }
  };

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
    setSelectedTable(0);
    setCurrentPage(1); // Reset to first page when tab changes
    IngredientTileInfo(getFilterValue(tab));
    setIngredientCustomDateRange(false);
  };

  const handleTableChange = (event) => {
    setSelectedTable(parseInt(event.target.value));
    // Make API call with the selected table value
    IngredientTileInfo(getFilterValue(currentTab), undefined, undefined, parseInt(event.target.value));
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const getCurrentItems = () => {
    switch (currentTab) {
      case "viewed":
        return IngredientviewcurrentItems;
      case "clicked":
        return IngredientclickedcurrentItems;
      case "crafted":
        return IngredientcraftedcurrentItems;
      case "liked":
        return IngredientlikecurrentItems;
      case "shared":
        return IngredientsharedcurrentItems;
      default:
        return [];
    }
  };

  const getTotalItems = () => {
    switch (currentTab) {
      case "viewed":
        return getFilteredItems(IngredientViewTile).length;
      case "clicked":
        return getFilteredItems(IngredientClickedTile).length;
      case "crafted":
        return getFilteredItems(IngredientCraftedTile).length;
      case "liked":
        return getFilteredItems(IngredientLikeTile).length;
      case "shared":
        return getFilteredItems(IngredientSharedTile).length;
      default:
        return 0;
    }
  };

  const getCurrentRange = () => {
    const start = (currentPage - 1) * itemsPerPage + 1;
    const end = Math.min(currentPage * itemsPerPage, getTotalItems());
    return `${start}-${end}`;
  };

  return (
    <>
      <div>
        <h6>
          <span style={{ color: "#3cd2a5" }}>PAGES</span> / INGREDIENTS
        </h6>
        <div className="row">
          <div className="col-xl-8">
            <h1>Ingredients</h1>
          </div>

          <div className="col-xl-4"></div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10 pt-3">
                <ul className="nav nav-underline" id="myTab" role="tablist">
                  {producttabs.map((tab, index) => (
                    <li className="nav-item" role="presentation" key={index}>
                      <button
                        className={`nav-link ${tab.active ? "active" : ""}`}
                        id={`${tab.id}-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#${tab.id}`}
                        type="button"
                        role="tab"
                        aria-controls={tab.id}
                        aria-selected={tab.active ? "true" : "false"}
                        onClick={() => handleTabChange(tab.id)}
                      >
                        {tab.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-7">
          <div className="row">
            <div className="col-md-2">
              <label htmlFor="date">Date</label>
              <Select
                options={dateOptions}
                value={ingredientselectedDateOption}
                onChange={handleDateOptionChangeIngredient}
                classNamePrefix="react-select"
              />

              <div className="col-xl-9">
                {ingredientcustomDateRange && (
                  <>
                    <label>From:</label>
                    <DatePicker
                      selected={ingredientstartDate}
                      onChange={(date) => {
                        setIngredientStartDate(date);
                        handleCustomDateRangeChange(date, ingredientendDate);
                      }}
                      className="form-control"
                    />
                    <br />
                    <label>To:</label>
                    <DatePicker
                      selected={ingredientendDate}
                      onChange={(date) => {
                        setIngredientEndDate(date);
                        handleCustomDateRangeChange(ingredientstartDate, date);
                      }}
                      className="form-control"
                    />
                  </>
                )}
              </div>
            </div>
            <div className="col-md-2 mt-3 pt-1">
              <Form.Control
                type="text"
                value={searchTerm} // Bind search term to input
                onChange={handleSearchChange} // Add search change handler
                placeholder="Search"
              />
            </div>

            <div className="col-md-6 mt-3 pt-1">
              <button className="btn btn-secondary">
                <i className="fas fa-search"></i>
              </button>
              &nbsp; &nbsp;
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="table"
                  id="table-0"
                  value={0}
                  checked={selectedTable === 0}
                  onChange={handleTableChange}
                />
                <label className="form-check-label" htmlFor="table-0">
                  Liquor Ingredients
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="table"
                  id="table-1"
                  value={1}
                  checked={selectedTable === 1}
                  onChange={handleTableChange}
                />
                <label className="form-check-label" htmlFor="table-1">
                  Mixer Ingredients
                </label>
              </div>
            </div>
            <div className="col-md-2 mt-4 pt-1">
  <p className="float-end">{getCurrentRange()} of {getTotalItems()}</p>
</div>

          </div>
        </div>
      </div>

      <div className="col-xl-12">
        <Card className="mt-3">
          <CardBody>
            <div className="tab-content" id="myTabContent">
              {producttabs.map((tab, index) => (
                <div
                  className={`tab-pane fade ${tab.active ? "show active" : ""}`}
                  id={tab.id}
                  role="tabpanel"
                  aria-labelledby={`${tab.id}-tab`}
                  key={index}
                >
                  {tabContent[index]}
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
        <div className="row mt-3">
          <div className="col-md-10"></div>
          <div className="col-md-2">
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={getTotalItems()}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Ingredient;
