import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Card, CardBody } from "./../../components/card/card.jsx";
import Chart from "react-apexcharts";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {
  dateOptions,
  AgeOptions,
  RecipeTypeOptions,
} from "./../../components/selectvalues/selectvalue.jsx";

import usershimmer from "../../assets/usershimmer.png";
import Pagination from "./../../components/pagination/Pagination.jsx"; // Import the Pagination component

const Users = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [UserTile, setDataUserTile] = useState([]);
  const [UserTotal, setDataUserTotalTile] = useState();
  const [UserList, setDataUserListTile] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(""); // Add search term state

  const [userselectedDateOption, setUserSelectedDateOption] = useState(
    dateOptions[1]
  );
  const [usercustomDateRange, setUserCustomDateRange] = useState(false);
  const [userstartDate, setUserStartDate] = useState(new Date());
  const [userendDate, setUserEndDate] = useState(new Date());
  const [userselectedAgeOption, setUserSelectedAgeOption] = useState(
    AgeOptions[0]
  );

  // Get the current date
  var currentDate = new Date();

  // Convert the current date to a timestamp in seconds
  var timestampInSeconds = Math.floor(currentDate.getTime() / 1000);

  useEffect(() => {
    UserTileInfo(userselectedDateOption.value);
  }, [
    userselectedDateOption,
    userselectedAgeOption,
    userstartDate,
    userendDate,
  ]);

  // Logic to get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Filter items based on search term
  const getFilteredItems = (items) => {
    return items.filter((item) =>
      item.username && item.username.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  

  const currentItems = getFilteredItems(UserList).slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const producttabs = [{ id: "receipes", label: "Viewed", active: true }];

  const tabContent = [
    <div className="table-responsive ">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : currentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        currentItems.map((product, index) => (
          <div key={index} className={"mb-3 p-3 product_border"}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <div
                      className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-60px"
                      style={{
                        backgroundImage: `url(${
                          product.image ? product.image : usershimmer
                        })`,
                      }}
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">
                      {product.username}
                    </div>
                    <div>{"Active Status"}</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div className={index < UserList.length - 1 ? "mb-2" : ""}>
                  <div className="row">
                    <div className="col-xl-12 col-12">
                      <div>
                        <label>Top Base:</label>
                        <span className="ms-4">{product.qty}</span>
                      </div>
                      <div>
                        <label>Top Mixer:</label>
                        <span className="ms-4">{product.revenue}</span>
                      </div>
                      <div>
                        <label>Top Cocktail:</label>
                        <span className="ms-2">{product.profit}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
  ];

  const handleChangeStartCrafted = (date) => {
    setUserStartDate(date);
  };

  const handleChangeEndCrafted = (date) => {
    setUserEndDate(date);
  };

  const handleAgeOptionChangeUser = (selectedOption) => {
    setUserSelectedAgeOption(selectedOption);
  };

  const dateToTimestampInSeconds = (date) => {
    return Math.floor(date.getTime() / 1000);
  };

  const calculateTimestampForAge = (age) => {
    const birthDate = new Date();
    birthDate.setFullYear(birthDate.getFullYear() - age);
    birthDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
    return Math.floor(birthDate.getTime() / 1000);
  };

  const UserTileInfo = async (filterValue) => {
    setIsLoading(true);
    try {
      let startTime = 0;
      let endTime = 0;
      if (userselectedDateOption.value === 3) {
        // Custom Date Range
        startTime = dateToTimestampInSeconds(userstartDate);
        endTime = dateToTimestampInSeconds(userendDate);
      }

      let ageStart = 0;
      let ageEnd = timestampInSeconds; // Default to current timestamp if no age filter is applied

      if (userselectedAgeOption.value !== "0") {
        // Age filter selected
        const [startAge, endAge] = userselectedAgeOption.value.split("-");
        ageStart = calculateTimestampForAge(parseInt(startAge));
        ageEnd = calculateTimestampForAge(parseInt(endAge));
      }

      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/userpageuserinfo?startTime=${startTime}&endTime=${endTime}&filter=0&datefilter=${filterValue}&table=User&country=0&agestart=${ageStart}&ageend=${ageEnd}`
      );
      const result = await response.json();
      setDataUserTile(result.dateswise);
      setDataUserTotalTile(result.totalRegisterUser);
      setDataUserListTile(result.userdata);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Ensures loading is turned off whether fetch succeeds or fails
    }
  };

  const handleDateOptionChangeUser = (selectedOption) => {
    setUserSelectedDateOption(selectedOption);
    if (selectedOption.value === 3) {
      setUserCustomDateRange(true);
    } else {
      setUserCustomDateRange(false);
    }
  };

  const userlabels = UserTile.map((item) => item.label);
  const userlinevalues = UserTile.map((item) => item.value);

  var themeFont = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-family")
    .trim();
  var themeFontWeight = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-weight")
    .trim();
  var gray500 = getComputedStyle(document.body)
    .getPropertyValue("--bs-gray-500")
    .trim();
  var orange = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  var inverse = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse")
    .trim();
  var inverseRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse-rgb")
    .trim();
  var borderColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-border-color")
    .trim();
  var indigo = getComputedStyle(document.body)
    .getPropertyValue("--bs-indigo")
    .trim();

  var UserChartOptions = {
    chart: {
      shadow: {
        enabled: true,
        color: "rgba(" + inverseRgb + ", .5)",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1,
      },
      toolbar: { show: false },
    },
    colors: [orange, gray500],
    dataLabels: {
      enabled: false,
      background: {
        enabled: true,
        padding: 4,
        borderRadius: 2,
        borderWidth: 0,
        opacity: 0.9,
        dropShadow: { enabled: false },
      },
    },
    stroke: { curve: "straight", width: 3 },
    grid: {
      borderColor: borderColor,
      row: {
        colors: ["rgba(" + inverseRgb + ", .25)", "transparent"],
        opacity: 0.5,
      },
    },
    markers: { size: 6 },
    xaxis: {
      categories: userlabels,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: {
      show: true,
      position: "top",
      offsetY: -10,
      horizontalAlign: "right",
      floating: true,
      fontFamily: themeFont,
      labels: { colors: inverse },
    },
  };

  var UserChartData = [{ name: "User", data: userlinevalues }];

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const getCurrentRange = () => {
    const start = (currentPage - 1) * itemsPerPage + 1;
    const end = Math.min(currentPage * itemsPerPage, UserList.length);
    return `${start}-${end}`;
  };

  return (
    <>
      <h6>
        <span style={{ color: "#3cd2a5" }}>PAGES</span> / USERS
      </h6>
      <div className="row">
        <div className="col-xl-8">
          <h1>USERS</h1>
        </div>
      </div>
      <div>
        <h3 style={{ color: "#3cd2a5" }}>{UserTotal}</h3>
      </div>

      <div className="row">
        <div className="row">
          <div className="col-md-3">
            <label htmlFor="date">Date</label>
            <Select
              options={dateOptions}
              value={userselectedDateOption}
              onChange={handleDateOptionChangeUser}
              classNamePrefix="react-select"
            />
            <div className="col-xl-9">
              {usercustomDateRange && (
                <>
                  <label>From:</label>
                  <DatePicker
                    selected={userstartDate}
                    onChange={handleChangeStartCrafted}
                    className="form-control"
                  />
                  <br></br>
                  <label>To:</label>
                  <DatePicker
                    selected={userendDate}
                    onChange={handleChangeEndCrafted}
                    className="form-control"
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <label>Type</label>
            <Select
              options={RecipeTypeOptions}
              classNamePrefix="react-select"
            />
          </div>
          <div className="col-md-3">
            <label>Users Age</label>
            <Select
              options={AgeOptions}
              value={userselectedAgeOption}
              onChange={handleAgeOptionChangeUser}
              classNamePrefix="react-select"
            />
          </div>
          <div className="col-md-3">
            <label>Location</label>
            <Select options={dateOptions} classNamePrefix="react-select" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 mt-3">
            <Form.Control
              type="text"
              value={searchTerm} // Bind search term to input
              onChange={handleSearchChange} // Add search change handler
              placeholder="Search"
            />
          </div>

          <div className="col-md-2 mt-3">
            <button className="btn btn-secondary">
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>

      <div className="col-lg-12 align-center">
        <Chart
          type="line"
          options={UserChartOptions}
          series={UserChartData}
          height={350}
        />
      </div>

      <div className="col-12 mb-5 pe-0">
        <p className="float-end">{getCurrentRange()} of {UserList.length}</p>
      </div>

      <div className="col-xl-12">
        <Card className="mt-3">
          <CardBody>
            <div className="tab-content" id="myTabContent">
              {producttabs.map((tab, index) => (
                <div
                  className={`tab-pane fade ${tab.active ? "show active" : ""}`}
                  id={tab.id}
                  role="tabpanel"
                  aria-labelledby={`${tab.id}-tab`}
                  key={index}
                >
                  {tabContent[index]}
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
        <div className="row mt-3">
          <div className="col-md-10"></div>
          <div className="col-md-2">
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={UserList.length}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
