import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { Card, CardBody } from '../../components/card/card';

const ModalComponent = ({ cardBodyContent }) => {
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  return (
    <>
      <a
        href="#/"
        onClick={() => handleShow(true)}
        className="text-inverse text-opacity-50 text-decoration-none"
      >
        <i className="bi bi-fullscreen"></i>
      </a>

      <Modal
        show={show}
        fullscreen={fullscreen}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>BARSYS</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Card>
            <CardBody>
              {cardBodyContent}
            </CardBody>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

export {ModalComponent};
