import React, { useEffect, useState } from "react";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import axios from "axios";
import { Card, CardBody } from "./../../components/card/card.jsx";
import Chart from "react-apexcharts";
import { Form } from "react-bootstrap";
import Masonry from "masonry-layout";
import Select from "react-select";
import {
  ModeOptions,
  DeviceOptions,
} from "./../../components/selectvalues/selectvalue.jsx";

const Hardware = () => {
  const [countryData, setCountryData] = useState();
  const [serverChartOptions, setServerChartOptions] = useState(
    getServerChartOptions()
  );
  const [chartOptions, setChartOptions] = useState(getChartOptions());
  const [sourceChartData, setSourceChartData] = useState();
  const [hardrecipesData, sethardrecipesData] = useState();



  const [totalrecipeData, setTotalRecipeData] = useState([]);
  const [totalactiveData, setTotalActiveData] = useState([]); 
  const [totalactivatedData, setTotalActivatedData] = useState([]); 


  const [sourceData, setSourceData] = useState();

  const [tdCoaster, setTDCoaster] = useState();
  const [tdCoaster2, setTDCoaster2] = useState();
  const [tdBarsys360, setTDCoaster360] = useState();
  const [tdBarsys2, setBarsys2] = useState();

  const [tadCoaster, setTADCoaster] = useState();
  const [tadCoaster2, setTADCoaster2] = useState();
  const [tadBarsys360, setTADCoaster360] = useState();
  const [tadBarsys2, setTADBarsys2] = useState();

  const [tacdCoaster, setTACDCoaster] = useState();
  const [tacdCoaster2, setTACDCoaster2] = useState();
  const [tacdBarsys360, setTACDCoaster360] = useState();
  const [tacdBarsys2, setTACDBarsys2] = useState();

  const [recipeCoaster, setRecipeCoaster] = useState();
  const [recipeCoaster2, setrecipeCoaster2] = useState();
  const [recipeBarsys360, setrecipeCoaster360] = useState();
  const [recipeBarsys2, setrecipeBarsys2] = useState();

  function renderMap() {
    var inverse = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse")
      .trim();
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    const map = document.getElementById("world-map");
    const mapElm = document.querySelectorAll(".jvm-tooltip");

    if (map) {
      for (let i = 0; i < mapElm.length; i++) {
        mapElm[i].remove();
      }
      map.innerHTML = "";

      new jsVectorMap({
        selector: "#world-map",
        map: "world",
        zoomButtons: true,
        normalizeFunction: "polynomial",
        hoverOpacity: 0.5,
        hoverColor: false,
        zoomOnScroll: false,
        series: { regions: [{ normalizeFunction: "polynomial" }] },
        labels: { markers: { render: (marker) => marker.name } },
        focusOn: { x: 0.5, y: 0.5, scale: 1 },
        markerStyle: {
          initial: { fill: themeColor, stroke: "none", r: 5 },
          hover: { fill: themeColor },
        },
        regionStyle: {
          initial: {
            fill: inverse,
            fillOpacity: 0.35,
            stroke: "none",
            strokeWidth: 0.4,
            strokeOpacity: 1,
          },
          hover: { fill: themeColor, fillOpacity: 0.9 },
        },
        backgroundColor: "transparent",
      });
    }
  }

  function getChartOptions() {
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    var themeColorRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme-rgb")
      .trim();

    return {
      bar: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      line: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        stroke: { curve: "straight", width: 2 },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      pie: {
        colors: [
          "rgba(" + themeColorRgb + ", 1)",
          "rgba(" + themeColorRgb + ", .75)",
          "rgba(" + themeColorRgb + ", .5)",
        ],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      donut: {
        colors: [
          "rgba(" + themeColorRgb + ", .15)",
          "rgba(" + themeColorRgb + ", .35)",
          "rgba(" + themeColorRgb + ", .55)",
          "rgba(" + themeColorRgb + ", .75)",
          "rgba(" + themeColorRgb + ", .95)",
        ],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: true },
          y: {
            title: {
              formatter: function (seriesName) {
                return ;
              },
            },
          },
        },
      },
    };
  }

  function getServerChartOptions() {
    var borderColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-border-color")
      .trim();
    var bodyColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-color")
      .trim();
    var inverseRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse-rgb")
      .trim();
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    var themeFont = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-font-family")
      .trim();

    return {
      chart: { toolbar: { show: false } },
      plotOptions: {
        bar: { horizontal: false, columnWidth: "55%", endingShape: "rounded" },
      },
      dataLabels: { enabled: false },
      grid: { show: true, borderColor: borderColor },
      stroke: { show: false },
      colors: ["rgba(" + inverseRgb + ", .15)", themeColor],
      legend: { fontFamily: themeFont, labels: { colors: bodyColor } },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        labels: { show: false },
        axisBorder: {
          show: true,
          color: borderColor,
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: -1,
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: borderColor,
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: bodyColor,
            fontSize: "12px",
            fontFamily: themeFont,
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      fill: { opacity: 0.65 },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    };
  }

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  // function CustomDropdown() {

  //   const handleDateChange = (event) => {
  //     if (event.target.name === 'fromDate') {
  //       setFromDate(event.target.value);
  //     } else {
  //       setToDate(event.target.value);
  //     }
  //   };

  //   return (
  //     <div className="custom-dropdown">
  //       <input type="date" name="fromDate" value={fromDate} onChange={handleDateChange} />
  //       <span>to</span>
  //       <input type="date" name="toDate" value={toDate} onChange={handleDateChange} />
  //     </div>
  //   );
  // }

  useEffect(() => {
    fetch("/assets/data/dashboard/country.json")
      .then((res) => res.json())
      .then((result) => {
        setCountryData(result);
      });

    fetch("/assets/data/dashboard/source.json")
      .then((res) => res.json())
      .then((result) => {
        setSourceData(result);
      });
    fetch("/assets/data/dashboard/source-chart.json")
      .then((res) => res.json())
      .then((result) => {
        setSourceChartData(result);
      });

    fetch("/assets/data/dashboard/hardrecipes.json")
      .then((res) => res.json())
      .then((result) => {
        sethardrecipesData(result);
      });

    fetch("/assets/data/dashboard/tacd.json")
      .then((res) => res.json())
      .then((result) => {
        // settotalactivatedData(result);
      });

    fetch("/assets/data/dashboard/tad.json")
      .then((res) => res.json())
      .then((result) => {
        // settotalactiveData(result);
      });

    renderMap();
    new Masonry("[data-masonry]");
    document.addEventListener("theme-reload", () => {
      setServerChartOptions(getServerChartOptions());
      setChartOptions(getChartOptions());
      renderMap();
    });

    // eslint-disable-next-line
  }, []);

  const price = 2750;
  const discountPercentage = 50;
  const discountedPrice = price - (price * discountPercentage) / 100;


  // Timestamp function to get time in seconds (divide by 1000)
  const getTimestamp = (date) => Math.floor(date.getTime() / 1000);

  // Function to calculate start and end time based on selected period
  const getStartEndTime = (period) => {
    const currentDate = new Date();
    let startTime;
    let endTime = getTimestamp(currentDate);

    switch (period) {
      case "Today":
        startTime = getTimestamp(new Date(currentDate.setHours(0, 0, 0, 0)));
        break;
      case "Yesterday":
        startTime = getTimestamp(new Date(currentDate.setDate(currentDate.getDate() - 1)));
        endTime = getTimestamp(new Date(currentDate.setHours(23, 59, 59, 999)));
        break;
      case "7d":
        startTime = getTimestamp(new Date(currentDate.setDate(currentDate.getDate() - 7)));
        break;
      case "30d":
        startTime = getTimestamp(new Date(currentDate.setDate(currentDate.getDate() - 30)));
        break;
      case "3m":
        startTime = getTimestamp(new Date(currentDate.setMonth(currentDate.getMonth() - 3)));
        break;
      case "6m":
        startTime = getTimestamp(new Date(currentDate.setMonth(currentDate.getMonth() - 6)));
        break;
      case "12m":
        startTime = getTimestamp(new Date(currentDate.setMonth(currentDate.getMonth() - 12)));
        break;
      default:
        startTime = 0;
    }

    return { startTime, endTime };
  };

  // Function to fetch data from API

  const fetchDataActivated = async (startTime, endTime) => {
    try {
      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/hardwareactivatedtileinfov2?startTime=${startTime}&endTime=${endTime}&filter=3`
      );
      const data = await response.json();
      const { coasterDataCount, Data360Count } = data;

      // Update state with the fetched data
      setTACDCoaster2(coasterDataCount);
      setTACDCoaster360(Data360Count);
      setTotalActivatedData([0,Data360Count,coasterDataCount,0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const fetchDataActive = async (startTime, endTime) => {
    try {
      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/hardwareactivetileinfov2?startTime=${startTime}&endTime=${endTime}&filter=3`
      );
      const data = await response.json();
      const { coasterDataCount, Data360Count } = data;

      // Update state with the fetched data
      setTADCoaster2(coasterDataCount);
      setTADCoaster360(Data360Count);
      setTotalActiveData([0,Data360Count,coasterDataCount,0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  // Button click handler to update data based on the selected period for CRAFTED RECIPES
  const fetchData = async (startTime, endTime) => {
    try {
      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/hardwarecraftedtileinfov2?startTime=${startTime}&endTime=${endTime}&filter=3`
      );
      const data = await response.json();
      const { coasterDataCount, Data360Count } = data;

      // Update state with the fetched data
      setrecipeCoaster2(coasterDataCount);
      setrecipeCoaster360(Data360Count);
      setTotalRecipeData([0,Data360Count,coasterDataCount,0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };




  const handlePeriodChange = (period) => {
    const { startTime, endTime } = getStartEndTime(period);
    fetchData(startTime, endTime);
    fetchDataActive(startTime, endTime);
    fetchDataActivated(startTime, endTime);
  };
  
  useEffect(() => {
    // Fetch data for the default period (e.g., "Today") on component mount
    
    // Function to fetch data from API using fetch
  
  handlePeriodChange("3m");
}, []);



  return (
    <>
      <div className="row mb-3" data-masonry='{"percentPosition": true }'>
        <h3>Filter</h3>

        <div className="col-xl-12">
        <button className="btn btn-secondary me-1" onClick={() => handlePeriodChange("Today")}>Today</button>
        <button className="btn btn-secondary me-1" onClick={() => handlePeriodChange("Yesterday")}>Yesterday</button>
        <button className="btn btn-secondary me-1" onClick={() => handlePeriodChange("7d")}>7d</button>
        <button className="btn btn-secondary me-1" onClick={() => handlePeriodChange("30d")}>30d</button>
        <button className="btn btn-secondary me-1" onClick={() => handlePeriodChange("3m")}>3m</button>
        <button className="btn btn-secondary me-1" onClick={() => handlePeriodChange("6m")}>6m</button>
        <button className="btn btn-secondary me-1" onClick={() => handlePeriodChange("12m")}>12m</button>
          {/* <CustomDropdown /> */}
        </div>
      </div>
      <div className="row" data-masonry='{"percentPosition": true }'>
        <div className="col-xl-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">GEOGRAPHICAL LOCATION</span>
              </div>
              <div className="row mb-3">
                <div className="col-md-4 ">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="date">Mode</label>
                    </div>
                    <div className="col-md-6">
                      <Select
                        options={ModeOptions}
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="age">Device</label>
                    </div>
                    <div className="col-md-7">
                      <Select
                        options={DeviceOptions}
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-1">
                  <button className="btn btn-secondary">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
              <div className="ratio ratio-21x9 mb-4">
                <div
                  id="world-map"
                  className="jvectormap-without-padding"
                ></div>
              </div>
              <div className="row gx-4">
                <div className="col-lg-12 mb-3  mb-lg-0">
                  <table className="w-100 small mb-0 text-truncate text-inverse text-opacity-60">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Active User</th>
                        <th>Cocktail Crafted</th>
                      </tr>
                      <tr className="text-inverse text-opacity-75">
                        <th className="w-50 ">COUNTRY</th>
                        <th className="w-25">
                          <div className="w-100">
                            <label className="w-50">Count</label>
                            <label className="w-50">PCT%</label>
                          </div>
                        </th>
                        <th className="w-25">
                          <div className="w-100">
                            <label className="w-50">Count</label>
                            <label className="w-50">PCT%</label>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {countryData && countryData.length > 0 ? (
                            countryData.slice(0, 3).map((country, index) => (
                              <tr key={index} className={country.class}>
                                <td>{country.name}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3">No records found</td>
                            </tr>
                          )}
                        </td>
                        <td>
                          {countryData && countryData.length > 0 ? (
                            countryData.slice(0, 3).map((country, index) => (
                              <div key={index} className={country.class}>
                                <label className="w-50">{country.visits}</label>
                                <label className="w-50">{country.pct}</label>
                              </div>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3">No records found</td>
                            </tr>
                          )}
                        </td>
                        <td>
                          {countryData && countryData.length > 0 ? (
                            countryData.slice(0, 3).map((country, index) => (
                              <div key={index} className={country.class}>
                                <label className="w-50">{country.visits}</label>
                                <label className="w-50">{country.pct}</label>
                              </div>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3">No records found</td>
                            </tr>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* <div className="col-lg-6">
                  <Card>
                    <CardBody className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="w-70px">
                          {sourceChartData && sourceChartData.length > 0 && (
                            <Chart
                              type="donut"
                              height="70"
                              options={chartOptions["donut"]}
                              series={sourceChartData}
                            />
                          )}
                        </div>
                        <div className="flex-1 ps-2">
                          <table className="w-100 small mb-0 text-inverse text-opacity-60">
                            <tbody>
                              {sourceData && sourceData.length > 0 ? (
                                sourceData.map((source, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div
                                          className={
                                            "w-6px h-6px rounded-pill me-2 " +
                                            source.class
                                          }
                                        ></div>
                                        {source.name}
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      {source.percentage}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="2">No records found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div> */}
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-lg-6">
          <Card className="mb-3">
            <CardBody className="py-2">
              TOTAL DEVICES
              <div className="d-flex align-items-center">
                <div className="w-60">
                  {sourceChartData && sourceChartData.length > 0 && (
                    <Chart
                      type="donut"
                      height="200"
                      options={chartOptions["donut"]}
                      series={sourceChartData}
                    />
                  )}
                </div>
                <div className="flex-1 ps-4">
                  <table className="w-100 small mb-0 text-inverse text-opacity-60">
                    <tbody>
                      {sourceData && sourceData.length > 0 ? (
                        sourceData.map((source, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div
                                  className={
                                    "w-12px h-12px rounded-pill me-3 " +
                                    source.class
                                  }
                                ></div>
                                {source.name}
                              </div>
                            </td>
                            {/* <td className="text-end">
                                      {source.percentage}
                                    </td> */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="2">No records found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-6">
                  <ul>
                    <li>
                      Coaster<br></br>
                      {/* {price}({discountPercentage}%) */}
                      86861
                    </li>
                  </ul>
                </div>
                <div className="col-xl-3 col-6">
                  <ul>
                    <li>
                      Coaster 2.0<br></br>
                      {/* {price}({discountPercentage}%) */}
                      3120
                    </li>
                  </ul>
                </div>
                <div className="col-xl-3 col-6">
                  <ul>
                    <li>
                      Barsys 360<br></br>
                      {/* {price}({discountPercentage}%) */}0
                    </li>
                  </ul>
                </div>
                <div className="col-xl-3 col-6">
                  <ul>
                    <li>
                      Barsys 2.0<br></br>
                      {/* {price}({discountPercentage}%) */}
                      1895
                    </li>
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-lg-6">
          <Card className="mb-3">
            <CardBody className="py-2">
              TOTAL ACTIVATED DEVICES
              <div className="d-flex align-items-center">
                <div className="w-60">
                  {totalactivatedData && totalactivatedData.length > 0 && (
                    <Chart
                      type="donut"
                      height="200"
                      options={chartOptions["donut"]}
                      series={totalactivatedData}
                    />
                  )}
                </div>
                <div className="flex-1 ps-4">
                  <table className="w-100 small mb-0 text-inverse text-opacity-60">
                    <tbody>
                      {sourceData && sourceData.length > 0 ? (
                        sourceData.map((source, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div
                                  className={
                                    "w-12px h-12px rounded-pill me-3 " +
                                    source.class
                                  }
                                ></div>
                                {source.name}
                              </div>
                            </td>
                            {/* <td className="text-end">
                                      {source.percentage}
                                    </td> */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="2">No records found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-6">
                  <ul>
                    <li>
                      Coaster<br></br>
                      {/* {price}({discountPercentage}%) */}
                      0
                    </li>
                  </ul>
                </div>
                <div className="col-xl-3 col-6">
                  <ul>
                    <li>
                      Coaster 2.0<br></br>
                      {/* {price}({discountPercentage}%) */}
                      {tacdCoaster2}
                    </li>
                  </ul>
                </div>
                <div className="col-xl-3 col-6">
                  <ul>
                    <li>
                      Barsys 360<br></br>
                      {/* {price}({discountPercentage}%) */}
                      {tacdBarsys360}
                    </li>
                  </ul>
                </div>
                <div className="col-xl-3 col-6">
                  <ul>
                    <li>
                      Barsys 2.0<br></br>
                      {/* {price}({discountPercentage}%) */}
                      0
                    </li>
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-lg-6">
          <Card className="mb-3">
            <CardBody className="py-2">
              TOTAL ACTIVE DEVICES
              <div className="d-flex align-items-center">
                <div className="w-60">
                  {totalactiveData && totalactiveData.length > 0 && (
                    <Chart
                      type="donut"
                      height="200"
                      options={chartOptions["donut"]}
                      series={totalactiveData}
                    />
                  )}
                </div>
                <div className="flex-1 ps-4">
                  <table className="w-100 small mb-0 text-inverse text-opacity-60">
                    <tbody>
                      {sourceData && sourceData.length > 0 ? (
                        sourceData.map((source, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div
                                  className={
                                    "w-12px h-12px rounded-pill me-3 " +
                                    source.class
                                  }
                                ></div>
                                {source.name}
                              </div>
                            </td>
                            {/* <td className="text-end">
                                      {source.percentage}
                                    </td> */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="2">No records found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-6">
                  <ul>
                    <li>
                      Coaster<br></br>
                      {/* {price}({discountPercentage}%) */}
                      0
                    </li>
                  </ul>
                </div>
                <div className="col-xl-3 col-6">
                  <ul>
                    <li>
                      Coaster 2.0<br></br>
                      {/* {price}({discountPercentage}%) */}
                      {tadCoaster2}
                    </li>
                  </ul>
                </div>
                <div className="col-xl-3 col-6">
                  <ul>
                    <li>
                      Barsys 360<br></br>
                      {/* {price}({discountPercentage}%) */}{tadBarsys360}
                    </li>
                  </ul>
                </div>
                <div className="col-xl-3 col-6">
                  <ul>
                    <li>
                      Barsys 2.0<br></br>
                      {/* {price}({discountPercentage}%) */}
                      0
                    </li>
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-lg-6">
          <Card className="mb-3">
            <CardBody className="py-2">
              RECIPES
              <div className="d-flex align-items-center">
                <div className="w-60">
                  {totalrecipeData && totalrecipeData.length > 0 && (
                    <Chart
                      type="donut"
                      height="200"
                      options={chartOptions["donut"]}
                      series={totalrecipeData}
                    />
                  )}
                </div>
                <div className="flex-1 ps-4">
                  <table className="w-100 small mb-0 text-inverse text-opacity-60">
                    <tbody>
                      {sourceData && sourceData.length > 0 ? (
                        sourceData.map((source, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div
                                  className={
                                    "w-12px h-12px rounded-pill me-3 " +
                                    source.class
                                  }
                                ></div>
                                {source.name}
                              </div>
                            </td>
                            {/* <td className="text-end">
                                      {source.percentage}
                                    </td> */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="2">No records found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-6">
                  <ul>
                    <li>
                      Coaster<br></br>
                      {/* {price}({discountPercentage}%) */}
                      0
                    </li>
                  </ul>
                </div>
                <div className="col-xl-3 col-6">
                  <ul>
                    <li>
                      Coaster 2.0<br></br>
                      {/* {price}({discountPercentage}%) */}
                      {recipeCoaster2}
                    </li>
                  </ul>
                </div>
                <div className="col-xl-3 col-6">
                  <ul>
                    <li>
                      Barsys 360<br></br>
                      {/* {price}({discountPercentage}%) */}
                      {recipeBarsys360}
                    </li>
                  </ul>
                </div>
                <div className="col-xl-3 col-6">
                  <ul>
                    <li>
                      Barsys 2.0<br></br>
                      {/* {price}({discountPercentage}%) */}
                      0
                    </li>
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Hardware;
