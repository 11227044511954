import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        
        const token = localStorage.getItem('isLoggedIn');
        return token === 'true';
    });
    const [userEmail, setUserEmail] = useState(() => {
        
        return localStorage.getItem('userEmail') || "";
    });

    useEffect(() => {
        localStorage.setItem('isLoggedIn', isLoggedIn);
        localStorage.setItem('userEmail', userEmail);
    }, [isLoggedIn, userEmail]);
    return (
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, userEmail, setUserEmail }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
