import React from 'react';

const Pagination = ({ currentPage, itemsPerPage, totalItems, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination float-end">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => paginate(1)}>
          &lt;&lt;
          </button>
        </li>
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => paginate(currentPage - 1)}>
            &lt;
          </button>
        </li>
        {pageNumbers.slice(
          Math.max(0, currentPage - 2),
          Math.min(Math.ceil(totalItems / itemsPerPage), currentPage + 2)
        ).map(page => (
          <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
            <button className="page-link" onClick={() => paginate(page)}>
              {page}
            </button>
          </li>
        ))}
        <li className={`page-item ${currentPage === Math.ceil(totalItems / itemsPerPage) ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => paginate(currentPage + 1)}>
            &gt;
          </button>
        </li>
        <li className={`page-item ${currentPage === Math.ceil(totalItems / itemsPerPage) ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => paginate(Math.ceil(totalItems / itemsPerPage))}>
          &gt;&gt;
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
