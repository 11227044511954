import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Card, CardBody } from "../../components/card/card";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import {
  dateOptions,
  AgeOptions,
} from "./../../components/selectvalues/selectvalue.jsx";
import Select from "react-select";

const Geomapexpand = ({ cardBodyContent }) => {
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  // Function to handle modal show
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  // Function to handle modal close
  function handleClose() {
    setShow(false);
  }

  // Initialize or re-render the map when the modal is shown
  function renderMap() {
    var inverse = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse")
      .trim();
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();

    const map1 = document.getElementById("expand-maps");
    const mapElm1 = document.querySelectorAll(".jvm-tooltip");

    if (map1) {
      // for (let i = 0; i < mapElm1.length; i++) {
      //   mapElm1[i].remove();
      // }

      map1.innerHTML = ""; // Clear existing map

    new jsVectorMap({
      selector: "#expand-maps",
      map: "world",
      zoomButtons: true,
      normalizeFunction: "polynomial",
      hoverOpacity: 0.9,
      hoverColor: true,
      zoomOnScroll: false,
      showTooltip: true,
      series: { regions: [{ normalizeFunction: "polynomial" }] },
      labels: { regions: { render: (marker) => marker.name } },
      focusOn: { x: 0.9, y: 0.9, scale: 1 },
      markerStyle: {
        initial: { fill: themeColor, stroke: "none", r: 5 },
        hover: { fill: themeColor },
      },
      regionStyle: {
        initial: {
          fill: inverse,
          fillOpacity: 0.35,
          stroke: "none",
          strokeWidth: 0.4,
          strokeOpacity: 1,
        },
        hover: { fill: themeColor, fillOpacity: 0.9 },
      },
      backgroundColor: "transparent",
      //  onRegionTipShow: function (event, label, code) {
      //   label.css({
      //     display: "block",
      //     position: "absolute",
      //     zIndex: 9999, // Set z-index of the tooltip
      //     backgroundColor: "#fff", // Background color
      //     padding: "5px 10px", // Padding
      //     border: "1px solid #ccc", // Border
      //   });

      //   // Display country name in the tooltip
      //   label.html(code + ": " + label.html());
      // },
    });
  }
  }

  useEffect(() => {
    if (show) {
      renderMap();

      document.addEventListener("theme-reload", () => {
        renderMap();
      });
    }
  }, [show]);

  return (
    <>
      <a
        href="#/"
        onClick={() => handleShow(true)}
        className="text-inverse text-opacity-50 text-decoration-none"
      >
        <i className="bi bi-fullscreen"></i>
      </a>

      <Modal
        show={show}
        fullscreen={fullscreen}
        onHide={handleClose}
        onEntered={renderMap} // Ensure map is rendered when modal is fully shown
      >
        <Modal.Header closeButton>
          <Modal.Title>BARSYS</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Card>
            <CardBody>
              {/* Use different ID for map container in modal */}

              <h3>GEOGRAPHICAL LOCATION</h3>
              <div className="row">
                <h2 className="col-xl-2">{0}</h2>

                <div className="col-md-2">
                  <label htmlFor="date">Date</label>
                  <Select
                    options={dateOptions}
                    classNamePrefix="react-select"
                  />
                </div>

                <div className="col-md-2">
                  <label htmlFor="age">Age</label>
                  <Select options={AgeOptions} classNamePrefix="react-select" />
                </div>
                <div className="col-md-2">
                  <label htmlFor="location">Location</label>
                  <select className="form-control" id="location">
                    <option value="all">All</option>
                  </select>
                </div>

                <div className="col-md-2 mt-3 pt-1">
                  <button className="btn btn-secondary">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
              <div className="ratio ratio-21x9 mb-3 mt-4">
                <div
                  id="expand-maps"
                  className="jvm-container"
                ></div>
              </div>
              {cardBodyContent}
            </CardBody>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { Geomapexpand };
