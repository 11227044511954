import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../AuthContext.js";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { ReactComponent as Logo1 } from "../../assets/barsys_hori.svg";
import loginimage1 from "../../assets/Barsys_login_main.jpg";
import styles from "./login.module.css";
import "bootstrap";
import { Toast } from 'bootstrap';

import { Card, CardBody } from "./../../components/card/card.jsx";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState("");
  const [toastmsg,setToast]=useState("")
  const { setIsLoggedIn, setUserEmail } = useAuth();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const loginData = { email, password };

    try {
      const response = await axios.post(
       process.env.REACT_APP_API_ADDRESS+"/api/users/login",
        loginData
      );
      // console.log(response);
      if (response.status === 200) {
        sessionStorage.setItem("isLoggedIn", "true");
        setIsLoggedIn(true);
        setUserEmail(email);
        setToast(response.data.message)
        navigate("/dashboard");
      } 
    } catch (err) {
      const toast2 = document.getElementById("toast-2");
      setToast(err.response?.data?.message)
      const toast = new Toast(toast2);
        toast.show();
      // console.error("Login failed:", err);
      setError(err.response?.data?.message || "An unexpected error occurred.");
    }
  };

  useEffect(() => {
    const toastTrigger1 = document.getElementById("showToast1");
    const toast1 = document.getElementById("toast-1");
    if (toastTrigger1) {
      toastTrigger1.addEventListener("click", () => {
        const toast = new Toast(toast1);
        toast.show();
      });
    }
  });

  return (
    <>
      <div className={styles.containerwrapper}>
        <Container fluid>
          <Row>
            <Col md={5} className="p-0 d-none d-md-block">
              {" "}
              {/* Add classes to hide image on mobile */}
              <img
                src={loginimage1}
                alt="Background"
                className={styles.mainimage}
              />
            </Col>
            <Col md={7} className="p-100">
              <Card className={styles.logincard} style={{ height: "100%" }}>
                <CardBody className={styles.carddata}>
                  <div className="d-flex justify-content-center mb-0">
                    <Logo1 width={140} height={120} />
                  </div>
                  <h5 className="text-center mb-4">
                    Welcome to Barsys's Dashboard
                  </h5>
                  <Form onSubmit={handleLogin}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <div className="input-group border-end-0">
                        <Form.Control
                          type={passwordVisible ? "text" : "password"}
                          placeholder="Password"
                          className="border-end-0"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <Button
                          variant="outline-secondary"
                          className="border-start-0"
                          onClick={togglePasswordVisibility}
                        >
                          {passwordVisible ? "Hide" : "Show"}
                        </Button>
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      {/* <Form.Check type="checkbox" label="Remember me" /> */}
                      <a href="##" className="float-end mb-3" id="showToast1">
                        Forgot password?
                      </a>
                    </Form.Group>
                    <div className="toasts-container">
                      <div
                        className="toast fade hide"
                        data-autohide="false"
                        id="toast-1"
                      >
                        {" "}
                        <div className="toast-header">
                          <i className="far fa-bell text-muted me-2"></i>
                          <strong className="me-auto">ALERT</strong>
                          {/* <small>11 mins ago</small> */}
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="toast"
                          ></button>
                        </div>
                        <div className="toast-body">
                          Please contact to Admin.
                        </div>
                      </div>
                      <div
                        className="toast fade hide"
                        data-autohide="false"
                        id="toast-2"
                      >
                        {" "}
                        <div className="toast-header">
                          <i className="far fa-bell text-muted me-2"></i>
                          <strong className="me-auto">ALERT</strong>
                          {/* <small>11 mins ago</small> */}
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="toast"
                          ></button>
                        </div>
                        <div className="toast-body">
                          {toastmsg}
                        </div>
                      </div>
                    </div>

                    <Button
                      type="submit"
                      className="w-100"
                      style={{ backgroundColor: "#3cd2a5", color: "black" }}
                      id="loginbtn"
                    >
                      Login
                    </Button>
                  </Form>
                  {/* <form>
                    <label
                      className="form-label"
                      htmlFor="exampleFormControlInput1"
                    >
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form-control mb-2"
                      id="exampleFormControlInput1"
                      placeholder="name@example.com"
                    />
                    <label htmlFor="inputPassword5" className="mb-2">Password</label>
                    <div className="input-group flex-nowrap">
                    <input
                      type="password"
                      id="inputPassword5"
                      className="form-control"
                      aria-describedby="passwordHelpBlock"
                    ></input>
                    <span className="input-group-text">EYE</span>
                    </div>
                  </form> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Login;
