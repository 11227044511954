
const dateOptions = [
    { value: 5, label: 'Daily' },
    { value: 2, label: 'Weekly' },
    { value: 1, label: 'Monthly' },
    { value: 0, label: 'Yearly' },
    { value: 3, label: 'Custom Date Range' }
  ];
  
  const AgeOptions = [
    { value: '0', label: 'All' },
    { value: '25-21', label: '21-25' },
    { value: '30-25', label: '25-30' },
    { value: '35-30', label: '30-35' },
    { value: '40-35', label: '35-40' },
    { value: '45-40', label: '40-45' },
    { value: '50-45', label: '45-50' },
    { value: '55-50', label: '50-55' },
    { value: '60-55', label: '55-60' },
    { value: '65-60', label: '60-65' },
    { value: '100-65', label: '65+' }
];

const userOptions = [
  { value: 'app', label: 'App' },
  { value: 'website', label: 'Website' },
  { value: 'app_device', label: 'App + Device' },
  { value: 'website_device', label: 'Website + Device' },
  { value: 'subscribers', label: 'Subscribers' }
];

const RecipeTypeOptions = [
  { value: 'Admin created', label: 'Admin created' },
  { value: 'User created', label: 'User created' },
  { value: 'Brand created', label: 'Brand created' },
  { value: 'Influencer/Mixologist created', label: 'Influencer/Mixologist created' },
  { value: 'Publication sourced', label: 'Publication sourced' },
  { value: 'Freelance writer created', label: 'Freelance writer created' }
];


const sortOptions = [
  { value: 'Views - Low to High', label: 'Views - Low to High' },
  { value: 'Views - High to Low', label: 'Views - High to Low' },
  { value: 'Clicks - Low to High', label: 'Clicks - Low to High' },
  { value: 'Clicks - High to Low', label: 'Clicks - High to Low' },
  { value: 'Likes - Low to High', label: 'Likes - Low to High' },
  { value: 'Likes - High to Low', label: 'Likes - High to Low' },
  { value: 'Shares - Low to High', label: 'Shares - Low to High' },
  { value: 'Shares - High to Low', label: 'Shares - High to Low' }
];

const locationOptions = [
  { value: 'Country', label: 'Country' },
  { value: 'Region/State', label: 'Region/State' },
  { value: 'MSA', label: 'MSA (Metropolitan Statistical Area) - Big Areas around cities of over 4M' },
  { value: 'Neighborhood', label: 'Neighborhood' }
];

const ModeOptions = [
    { value: 'All', label: 'All' },
    { value: 'Activated', label: 'Activated' },
    { value: 'Active', label: 'Active' }
  ];

const DeviceOptions = [
    { value: 'Coaster', label: 'Coaster' },
    { value: 'Coaster 2.0', label: 'Coaster 2.0' },
    { value: 'Barsys 360', label: 'Barsys 360' },
    { value: 'Barsys 2.0', label: 'Barsys 2.0' }
]

const GAUserOptions = [
  {value: "activeUsers", label: "Users"},
  {value: "average_daily_purchase", label: "Average Daily Purchase"},
  {value: "dauPerMau", label: "DAU/MAU"},
  {value: "dauPerWau", label: "DAU/WAU"},
  {value: "firstTimePurchaserConversionRate", label: "FTP Conversion"},
  {value: "firstTimePurchasers", label: "First Time Purchasers"},
  {value: "firstTimePurchasersPerNewUser", label: "FTPs per New User"},
  {value: "newUsers", label: "New Users"},
  {value: "dauPerMau", label: "30-Day Paid Active Users"},
  {value: "dauPerWau", label: "7-Day Paid Active Users"},
  {value: "90_day_paid_active_users", label: "90-Day Paid Active Users"},
  {value: "pmau_dau_ratio", label: "PMAU/DAU"},
  {value: "pwau_dau_ratio", label: "PWAU/DAU"},
  {value: "1_day_repeat_purchasers", label: "1-Day Repeat Purchasers"},
  {value: "2_7_day_repeat_purchasers", label: "2-7 Day Repeat Purchasers"},
  {value: "31_90_day_repeat_purchasers", label: "31-90 Day Repeat Purchasers"},
  {value: "8_30_day_repeat_purchasers", label: "8-30 Day Repeat Purchasers"},
  {value: "newVsReturning", label: "Returning Users"},
  {value: "totalPurchasers", label: "Total Purchasers"},
  {value: "totalUsers", label: "Total Users"},
  {value: "userConversionRate", label: "User Conversion Rate"},
  {value: "engagementRate", label: "Average Engagement Time per Session"},
  {value: "wauPerMau", label: "WAU/MAU"}
]

const GAEcommerceOptions = [
  {value: "addToCarts", label: "Add to Carts"},
  {value: "checkouts", label: "Checkouts"},
  {value: "ecommercePurchases", label: "Ecommerce Purchases"},
  {value: "ecommerce_quantity", label: "Ecommerce Quantity"},
  {value: "purchaseRevenue", label: "Purchase Revenue"},
  {value: "quantity", label: "Quantity"},
  {value: "purchases", label: "Purchases"},
  {value: "refundAmount", label: "Refund Amount"},
  {value: "refunds", label: "Refunds"},
  {value: "grossPurchaseRevenue", label: "Gross Purchase Revenue"},
  {value: "shippingAmount", label: "Shipping Amount"},
  {value: "tax_amount", label: "Tax Amount"},
  {value: "taxAmount", label: "Transactions"},
  {value: "transactionsPerPurchaser", label: "Transactions per Purchasers"}
]


const GARevenueOptions = [
  {value: "averageRevenuePerUser", label: "ARPU"},
  {value: "average_daily_revenue", label: "Average Daily Revenue"},
  {value: "averagePurchaseRevenuePerPayingUser", label: "ARPPU"},
  {value: "averagePurchaseRevenuePerUser", label: "Average Purchase Revenue Per User"},
  {value: "averagePurchaseRevenue", label: "Average Purchase Revenue"},
  {value: "totalRevenue", label: "Total Revenue"},
  {value: "max_daily_revenue", label: "Max Daily Revenue"},
  {value: "min_daily_revenue", label: "Min Daily Revenue"}
]


const GAEventOptions = [
  {value: "conversions", label: "Conversions"},
  {value: "eventCount", label: "Event Count"},
  {value: "eventCountPerUser", label: "Event Count Per User"},
  {value: "eventValue", label: "Event Value"},
  {value: "eventsPerSession", label: "Event Per Session"},
  {value: "first_open", label: "First Opens"},
  {value: "newUsers", label: "First Visits"}
]


const GAPageScreenOptions = [
  {value: "screenPageViews", label: "Views"},
  {value: "screenPageViewsPerSession", label: "Views Per Session"},
  {value: "screenPageViewsPerUser", label: "Views Per User"}
]

const GASessionOptions = [
  {value: "averageSessionDuration", label: "Average Session Duration"},
  {value: "bounceRate", label: "Bounce Rate"},
  {value: "engagedSessions", label: "Engaged Sessions"},
  {value: "engaged_sessions_per_user", label: "Engaged Sessions Per User"},
  {value: "engagementRate", label: "Engagement Rate"},
  {value: "sessionConversionRate", label: "Session Conversion Rate"},
  {value: "sessions", label: "Sessions"},
  {value: "sessionsPerUser", label: "Session Per User"}
]


const GAReportFirstUserOption = [
  {value: "firstUserPrimaryChannelGroup", label: "First User Primary Channel Group (Default)"},
  {value: "firstUserDefaultChannelGroup", label: "First User Default Channel Group"},
  {value: "firstUserSource", label: "First User Source"},
  {value: "firstUserMedium", label: "First User Medium"},
  {value: "firstUserSourceMedium", label: "First User Source / Medium"},
  {value: "firstUserSourcePlatform", label: "First User Platform"},
  {value: "firstUserCampaignName", label: "First User Campaign"},
  {value: "firstUserGoogleAdsAdNetworkType", label: "First user Google Ads as network type"},
  {value: "firstUserGoogleAdsAdGroupName", label: "First user Google Ads as group name"}
]

const GAReportSessionmainOption = [
  {value: "sessions", label: "Session"},
  {value: "engagedSessions", label: "Engaged Sessions"}
]

const GAReportSessionOption = [
  {value: "sessionPrimaryChannelGroup", label: "Session Primary Channel Group (Default)"},
  {value: "sessionDefaultChannelGroup", label: "Session Default Channel Group"},
  {value: "sessionSource", label: "Session Source"},
  {value: "sessionMedium", label: "Session Medium"},
  {value: "sessionSourceMedium", label: "Session Source/Medium"},
  {value: "sessionSourcePlatform", label: "Session Source Platform"},
  {value: "sessionCampaignName", label: "Session Campaign"}
]

const GAReportPlatformOption = [
  {value: "keyEvents", label: "Key Events"},
  {value: "eventCount", label: "Event Count"},
  {value: "totalRevenue", label: "Total Revenue"}
]


const GADateOptions = [
  {value: "today", label: "Today"},
  {value: "yesterday", label: "Yesterday"},
  {value: "thisWeek", label: "This Week"},
  {value: "lastWeek", label: "Last Week"},
  {value: "7daysAgo", label: "Last 7 Days"},
  {value: "14daysAgo", label: "Last 14 Days"},
  {value: "28daysAgo", label: "Last 28 Days"},
  {value: "30daysAgo", label: "Last 30 Days"},
  {value: "60daysAgo", label: "Last 60 Days"},
  {value: "90daysAgo", label: "Last 90 Days"},
  // {value: "120daysAgo", label: "Quarter to Date"},
  {value: "365daysAgo", label: "Last 12 Months"},
  {value: "thisYearJanToToday", label: "This Year (Jan - Today)"},
  {value: "lastYear", label: "Last Year"}
]

const CountryNames = {
  "Afghanistan": "AF",
  "Albania": "AL",
  "Algeria": "DZ",
  "Angola": "AO",
  "Argentina": "AR",
  "Armenia": "AM",
  "Australia": "AU",
  "Austria": "AT",
  "Azerbaijan": "AZ",
  "Bahrain": "BH",
  "Bangladesh": "BD",
  "Belarus": "BY",
  "Belgium": "BE",
  "Belize": "BZ",
  "Benin": "BJ",
  // Continue listing all countries supported by jVectorMap
  "Brazil": "BR",
  "Brunei Darussalam": "BN",
  "Bulgaria": "BG",
  "Burkina Faso": "BF",
  "Burundi": "BI",
  "Cambodia": "KH",
  "Cameroon": "CM",
  "Canada": "CA",
  "Cape Verde": "CV",
  "Central African Republic": "CF",
  "Chad": "TD",
  "Chile": "CL",
  "China": "CN",
  "Colombia": "CO",
  "Comoros": "KM",
  "Congo": "CG",
  "Democratic Republic of the Congo": "CD",
  "Costa Rica": "CR",
  "Côte d'Ivoire": "CI",
  "Croatia": "HR",
  "Cuba": "CU",
  "Cyprus": "CY",
  "Czech Republic": "CZ",
  "Denmark": "DK",
  "Djibouti": "DJ",
  "Dominican Republic": "DO",
  "Ecuador": "EC",
  "Egypt": "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  "Eritrea": "ER",
  "Estonia": "EE",
  "Ethiopia": "ET",
  "Fiji": "FJ",
  "Finland": "FI",
  "France": "FR",
  "Gabon": "GA",
  "Gambia": "GM",
  "Georgia": "GE",
  "Germany": "DE",
  "Ghana": "GH",
  "Greece": "GR",
  "Guatemala": "GT",
  "Guinea": "GN",
  "Guinea-Bissau": "GW",
  "Guyana": "GY",
  "Haiti": "HT",
  "Honduras": "HN",
  "Hong Kong": "HK",
  "Hungary": "HU",
  "Iceland": "IS",
  "India": "IN",
  "Indonesia": "ID",
  "Iran": "IR",
  "Iraq": "IQ",
  "Ireland": "IE",
  "Israel": "IL",
  "Italy": "IT",
  "Jamaica": "JM",
  "Japan": "JP",
  "Jordan": "JO",
  "Kazakhstan": "KZ",
  "Kenya": "KE",
  "Democratic People's Republic of Korea": "KP",
  "Republic of Korea": "KR",
  "Kuwait": "KW",
  "Kyrgyzstan": "KG",
  "Lao People's Democratic Republic": "LA",
  "Latvia": "LV",
  "Lebanon": "LB",
  "Lesotho": "LS",
  "Liberia": "LR",
  "Libyan Arab Jamahiriya": "LY",
  "Lithuania": "LT",
  "Luxembourg": "LU",
  "Macedonia": "MK",
  "Madagascar": "MG",
  "Malawi": "MW",
  "Malaysia": "MY",
  "Mali": "ML",
  "Malta": "MT",
  "Mauritania": "MR",
  "Mauritius": "MU",
  "Mexico": "MX",
  "Moldova": "MD",
  "Mongolia": "MN",
  "Montenegro": "ME",
  "Morocco": "MA",
  "Mozambique": "MZ",
  "Myanmar": "MM",
  "Namibia": "NA",
  "Nepal": "NP",
  "Netherlands": "NL",
  "New Zealand": "NZ",
  "Nicaragua": "NI",
  "Niger": "NE",
  "Nigeria": "NG",
  "Norway": "NO",
  "Oman": "OM",
  "Pakistan": "PK",
  "Panama": "PA",
  "Papua New Guinea": "PG",
  "Paraguay": "PY",
  "Peru": "PE",
  "Philippines": "PH",
  "Poland": "PL",
  "Portugal": "PT",
  "Puerto Rico": "PR",
  "Qatar": "QA",
  "Romania": "RO",
  "Russian Federation": "RU",
  "Rwanda": "RW",
  "Saudi Arabia": "SA",
  "Senegal": "SN",
  "Serbia": "RS",
  "Seychelles": "SC",
  "Sierra Leone": "SL",
  "Singapore": "SG",
  "Slovakia": "SK",
  "Slovenia": "SI",
  "Somalia": "SO",
  "South Africa": "ZA",
  "Spain": "ES",
  "Sri Lanka": "LK",
  "Sudan": "SD",
  "Suriname": "SR",
  "Swaziland": "SZ",
  "Sweden": "SE",
  "Switzerland": "CH",
  "Syrian Arab Republic": "SY",
  "Taiwan": "TW",
  "Tajikistan": "TJ",
  "Tanzania": "TZ",
  "Thailand": "TH",
  "Timor-Leste": "TL",
  "Togo": "TG",
  "Trinidad and Tobago": "TT",
  "Tunisia": "TN",
  "Turkey": "TR",
  "Turkmenistan": "TM",
  "Uganda": "UG",
  "Ukraine": "UA",
  "United Arab Emirates": "AE",
  "United Kingdom": "GB",
  "United States": "US",
  "Uruguay": "UY",
  "Uzbekistan": "UZ",
  "Venezuela": "VE",
  "Vietnam": "VN",
  "Yemen": "YE",
  "Zambia": "ZM",
  "Zimbabwe": "ZW"
};





export  {dateOptions,AgeOptions,userOptions,RecipeTypeOptions,sortOptions,locationOptions,ModeOptions,DeviceOptions,GAUserOptions,GADateOptions,GAEcommerceOptions,GAEventOptions,GARevenueOptions,GASessionOptions,GAPageScreenOptions,GAReportFirstUserOption,GAReportSessionOption,GAReportSessionmainOption,GAReportPlatformOption,CountryNames};