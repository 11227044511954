import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Card, CardBody } from "./../../components/card/card.jsx";
import Select from "react-select";
import { dateOptions } from "./../../components/selectvalues/selectvalue.jsx";
import glassshimmer from "../../assets/glassshimmer.png";
import DatePicker from "react-datepicker";
import Pagination from "./../../components/pagination/Pagination.jsx";

const Recipe = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [currentTab, setCurrentTab] = useState("viewed");
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const [ReceipeViewTile, setReceipeViewTile] = useState([]);
  const [ReceipeClickedTile, setReceipeClickedTile] = useState([]);
  const [ReceipeCraftedTile, setReceipeCraftedTile] = useState([]);
  const [ReceipeLikeTile, setReceipeLikeTile] = useState([]);
  const [ReceipeSharedTile, setReceipeSharedTile] = useState([]);

  const [receipesselectedDateOption, setReceipesSelectedDateOption] = useState(
    dateOptions[1]
  );
  const [receipescustomDateRange, setReceipeCustomDateRange] = useState(false);
  const [receipesstartDate, setReceipesStartDate] = useState(new Date());
  const [receipesendDate, setReceipesEndDate] = useState(new Date());

  const currentDate = new Date();
  const timestampInSeconds = Math.floor(currentDate.getTime() / 1000);

  // Get current items with search filter applied
  const getFilteredItems = (items) => {
    return items.filter((item) =>
      item.recipe.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const receipeviewcurrentItems = getFilteredItems(ReceipeViewTile).slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const receipeclickedcurrentItems = getFilteredItems(ReceipeClickedTile).slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const receipecraftedcurrentItems = getFilteredItems(ReceipeCraftedTile).slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const receipelikecurrentItems = getFilteredItems(ReceipeLikeTile).slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const receipesharedcurrentItems = getFilteredItems(ReceipeSharedTile).slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const producttabs = [
    { id: "viewed", label: "Viewed", active: true },
    { id: "clicked", label: "Clicked", active: false },
    { id: "crafted", label: "Crafted", active: false },
    { id: "liked", label: "Liked", active: false },
    { id: "shared", label: "Shared", active: false },
  ];

  const tabContent = [
    <div className="table-responsive ">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : receipeviewcurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        receipeviewcurrentItems.map((product, index) => (
          <div key={index} className={"mb-3 p-3 product_border"}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <div
                      className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-60px"
                      style={{
                        backgroundImage: `url(${
                          product.recipe.image
                            ? product.recipe.image
                            : glassshimmer
                        })`,
                      }}
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">
                      {product.recipe.name}
                    </div>
                    <div>Crafted By:{"Username"}</div>
                    <div className="mb-1">
                      <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                        {"Barsys"}
                      </small>
                    </div>
                  </div>
                  <div className="flex-1 ms-4">
                    <label>Views:</label>
                    <span className="ms-3" style={{ color: "#3CD25D" }}>
                      {product.count}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div
                  className={index < ReceipeViewTile.length - 1 ? "mb-2" : ""}
                >
                  <div className="row">
                    <div className="col-xl-12 col-12">
                      <div>
                        <label>Base:</label>
                        <span className="ms-4">{product.recipe.baseList}</span>
                      </div>
                      <div>
                        <label>Mixer:</label>
                        <span className="ms-4">{product.recipe.mixerlist}</span>
                      </div>
                      <div>
                        <label>Garnish:</label>
                        <span className="ms-2">
                          {product.recipe.garnishlist}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
    <div className="table-responsive">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : receipeclickedcurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        receipeclickedcurrentItems.map((product, index) => (
          <div key={index} className={"mb-3 p-3 product_border"}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <div
                      className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-60px"
                      style={{
                        backgroundImage: `url(${
                          product.recipe.image
                            ? product.recipe.image
                            : glassshimmer
                        })`,
                      }}
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">
                      {product.recipe.name}
                    </div>
                    <div>Crafted By:{"Username"}</div>
                    <div className="mb-1">
                      <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                        {"Barsys"}
                      </small>
                    </div>
                  </div>
                  <div className="flex-1 ms-4">
                    <label>Clicks:</label>
                    <span className="ms-3" style={{ color: "#3CD25D" }}>
                      {product.count}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div
                  className={
                    index < receipeclickedcurrentItems.length - 1 ? "mb-2" : ""
                  }
                >
                  <div className="row">
                    <div className="col-xl-12 col-12">
                      <div>
                        <label>Base:</label>
                        <span className="ms-4">{product.recipe.baseList}</span>
                      </div>
                      <div>
                        <label>Mixer:</label>
                        <span className="ms-4">{product.recipe.mixerlist}</span>
                      </div>
                      <div>
                        <label>Garnish:</label>
                        <span className="ms-2">
                          {product.recipe.garnishlist}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
    <div className="table-responsive ">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : receipecraftedcurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        receipecraftedcurrentItems.map((product, index) => (
          <div key={index} className={"mb-3 p-3 product_border"}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <div
                      className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-60px"
                      style={{
                        backgroundImage: `url(${
                          product.recipe.image
                            ? product.recipe.image
                            : glassshimmer
                        })`,
                      }}
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">
                      {product.recipe.name}
                    </div>
                    <div>Crafted By:{"Username"}</div>
                    <div className="mb-1">
                      <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                        {"Barsys"}
                      </small>
                    </div>
                  </div>
                  <div className="flex-1 ms-4">
                    <label>Crafts:</label>
                    <span className="ms-3" style={{ color: "#3CD25D" }}>
                      {product.count}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div
                  className={index < ReceipeViewTile.length - 1 ? "mb-2" : ""}
                >
                  <div className="row">
                    <div className="col-xl-12 col-12">
                      <div>
                        <label>Base:</label>
                        <span className="ms-4">{product.recipe.baseList}</span>
                      </div>
                      <div>
                        <label>Mixer:</label>
                        <span className="ms-4">{product.recipe.mixerlist}</span>
                      </div>
                      <div>
                        <label>Garnish:</label>
                        <span className="ms-2">
                          {product.recipe.garnishlist}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
    <div className="table-responsive ">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : receipelikecurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        receipelikecurrentItems.map((product, index) => (
          <div key={index} className={"mb-3 p-3 product_border"}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <div
                      className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-60px"
                      style={{
                        backgroundImage: `url(${
                          product.recipe.image
                            ? product.recipe.image
                            : glassshimmer
                        })`,
                      }}
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">
                      {product.recipe.name}
                    </div>
                    <div>Crafted By:{"Username"}</div>
                    <div className="mb-1">
                      <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                        {product.sku}
                      </small>
                    </div>
                  </div>
                  <div className="flex-1 ms-4">
                    <label>Liked:</label>
                    <span className="ms-3" style={{ color: "#3cd2a5" }}>
                      {product.qty}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div
                  className={index < ReceipeLikeTile.length - 1 ? "mb-2" : ""}
                >
                  <div className="row">
                    <div className="col-xl-12 col-12">
                      <div>
                        <label>Base:</label>
                        <span className="ms-4">{product.qty}</span>
                      </div>
                      <div>
                        <label>Mixer:</label>
                        <span className="ms-4">{product.revenue}</span>
                      </div>
                      <div>
                        <label>Garnish:</label>
                        <span className="ms-2">{product.profit}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
    <div className="table-responsive ">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : receipesharedcurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        receipesharedcurrentItems.map((product, index) => (
          <div key={index} className={"mb-3 p-3 product_border"}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <div
                      className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-60px"
                      style={{
                        backgroundImage: `url(${product.img})`,
                      }}
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">{product.title}</div>
                    <div>Crafted By:{"Username"}</div>
                    <div className="mb-1">
                      <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                        {product.sku}
                      </small>
                    </div>
                  </div>
                  <div className="flex-1 ms-4">
                    <label>Shared:</label>
                    <span className="ms-3" style={{ color: "#3cd2a5" }}>
                      {product.qty}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div
                  className={index < ReceipeSharedTile.length - 1 ? "mb-2" : ""}
                >
                  <div className="row">
                    <div className="col-xl-12 col-12">
                      <div>
                        <label>Base:</label>
                        <span className="ms-4">{product.qty}</span>
                      </div>
                      <div>
                        <label>Mixer:</label>
                        <span className="ms-4">{product.revenue}</span>
                      </div>
                      <div>
                        <label>Garnish:</label>
                        <span className="ms-2">{product.profit}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
  ];

  useEffect(() => {
    const endTimestamp = timestampInSeconds;
    const startTimestamp = endTimestamp - 7 * 24 * 60 * 60; // 7 days in seconds
    const defaultSelectedOption = dateOptions[1]; // Weekly by default

    setReceipesSelectedDateOption(defaultSelectedOption);
    setReceipesStartDate(new Date(startTimestamp * 1000)); // Convert seconds to milliseconds
    setReceipesEndDate(new Date(endTimestamp * 1000)); // Convert seconds to milliseconds

    ReceipeTileInfo(getFilterValue(currentTab), startTimestamp, endTimestamp);
  }, [currentTab]);

  const handleChangeStartRecipe = (date) => {
    setReceipesStartDate(date);
  };

  const handleChangeEndRecipe = (date) => {
    setReceipesEndDate(date);
  };

  const handleDateOptionChangeRecipe = (selectedOption) => {
    setReceipesSelectedDateOption(selectedOption);
    if (selectedOption.value === 3) {
      setReceipeCustomDateRange(true);
    } else {
      setReceipeCustomDateRange(false);
      const endTimestamp = timestampInSeconds;
      let startTimestamp;
      switch (selectedOption.value) {
        case 5: // Daily
          startTimestamp = endTimestamp - 24 * 60 * 60; // 1 day in seconds
          break;
        case 2: // Weekly
          startTimestamp = endTimestamp - 7 * 24 * 60 * 60; // 7 days in seconds
          break;
        case 1: // Monthly
          const currentDate = new Date();
          startTimestamp =
            new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              1
            ).getTime() / 1000;
          break;
        case 0: // Yearly
          startTimestamp =
            new Date(new Date().getFullYear(), 0, 1).getTime() / 1000;
          break;
        default:
          return;
      }

      setReceipesStartDate(new Date(startTimestamp * 1000)); // Convert seconds to milliseconds
      setReceipesEndDate(new Date(endTimestamp * 1000)); // Convert seconds to milliseconds
      ReceipeTileInfo(getFilterValue(currentTab), startTimestamp, endTimestamp);
    }
  };

  const dateToTimestampInSeconds = (date) => {
    return Math.floor(date.getTime() / 1000);
  };

  const handleCustomDateRangeChange = (startDate, endDate) => {
    const startTimestamp = dateToTimestampInSeconds(startDate);
    const endTimestamp = dateToTimestampInSeconds(endDate);
    ReceipeTileInfo(getFilterValue(currentTab), startTimestamp, endTimestamp);
  };

  const ReceipeTileInfo = async (
    filterValue,
    startTime = null,
    endTime = null
  ) => {
    setIsLoading(true);
    try {
      if (!startTime || !endTime) {
        startTime = dateToTimestampInSeconds(receipesstartDate);
        endTime = dateToTimestampInSeconds(receipesendDate);
      }
      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/recipepageinfo?startTime=${startTime}&endTime=${endTime}&filter=${filterValue}&table=0`
      );
      const result = await response.json();
      switch (filterValue) {
        case 0:
          setReceipeViewTile(result);
          break;
        case 1:
          setReceipeClickedTile(result);
          break;
        case 2:
          setReceipeCraftedTile(result);
          break;
        case 3:
          setReceipeLikeTile(result);
          break;
        case 4:
          setReceipeSharedTile(result);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getFilterValue = (tab) => {
    switch (tab) {
      case "viewed":
        return 0;
      case "clicked":
        return 1;
      case "crafted":
        return 2;
      case "liked":
        return 3;
      case "shared":
        return 4;
      default:
        return 0;
    }
  };

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
    setCurrentPage(1);
    ReceipeTileInfo(getFilterValue(tab));
    setReceipeCustomDateRange(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const getCurrentItems = () => {
    switch (currentTab) {
      case "viewed":
        return receipeviewcurrentItems;
      case "clicked":
        return receipeclickedcurrentItems;
      case "crafted":
        return receipecraftedcurrentItems;
      case "liked":
        return receipelikecurrentItems;
      case "shared":
        return receipesharedcurrentItems;
      default:
        return [];
    }
  };

  const getTotalItems = () => {
    switch (currentTab) {
      case "viewed":
        return getFilteredItems(ReceipeViewTile).length;
      case "clicked":
        return getFilteredItems(ReceipeClickedTile).length;
      case "crafted":
        return getFilteredItems(ReceipeCraftedTile).length;
      case "liked":
        return getFilteredItems(ReceipeLikeTile).length;
      case "shared":
        return getFilteredItems(ReceipeSharedTile).length;
      default:
        return 0;
    }
  };

  const getCurrentRange = () => {
    const start = (currentPage - 1) * itemsPerPage + 1;
    const end = Math.min(currentPage * itemsPerPage, getTotalItems());
    return `${start}-${end}`;
  };

  return (
    <>
      <div>
        <h6>
          <span style={{ color: "#3cd2a5" }}>PAGES</span> / RECIPE
        </h6>
        <h1>Recipes</h1>
      </div>

      <div className="row">
        <div className="col-md-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 pt-3">
                <ul className="nav nav-underline" id="myTab" role="tablist">
                  {producttabs.map((tab, index) => (
                    <li className="nav-item" role="presentation" key={index}>
                      <button
                        className={`nav-link ${tab.active ? "active" : ""}`}
                        id={`${tab.id}-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#${tab.id}`}
                        type="button"
                        role="tab"
                        aria-controls={tab.id}
                        aria-selected={tab.active ? "true" : "false"}
                        onClick={() => handleTabChange(tab.id)}
                      >
                        {tab.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-7">
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="date">Date</label>
              <Select
                options={dateOptions}
                value={receipesselectedDateOption}
                onChange={handleDateOptionChangeRecipe}
                classNamePrefix="react-select"
              />
              <div className="col-xl-9">
                {receipescustomDateRange && (
                  <>
                    <label>From:</label>
                    <DatePicker
                      selected={receipesstartDate}
                      onChange={(date) => {
                        setReceipesStartDate(date);
                        handleCustomDateRangeChange(date, receipesendDate);
                      }}
                      className="form-control"
                    />
                    <br />
                    <label>To:</label>
                    <DatePicker
                      selected={receipesendDate}
                      onChange={(date) => {
                        setReceipesEndDate(date);
                        handleCustomDateRangeChange(receipesstartDate, date);
                      }}
                      className="form-control"
                    />
                  </>
                )}
              </div>
            </div>
            <div className="col-md-4 mt-3 pt-1">
              <Form.Control
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search"
              />
            </div>

            <div className="col-md-2 mt-3 pt-1">
              <button className="btn btn-secondary">
                <i className="fas fa-search"></i>
              </button>
            </div>

            <div className="col-md-2 mt-4 pt-1">
              <p className="float-end">
                {getCurrentRange()} of {getTotalItems()}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-12">
        <Card className="mt-3">
          <CardBody>
            <div className="tab-content" id="myTabContent">
              {producttabs.map((tab, index) => (
                <div
                  className={`tab-pane fade ${tab.active ? "show active" : ""}`}
                  id={tab.id}
                  role="tabpanel"
                  aria-labelledby={`${tab.id}-tab`}
                  key={index}
                >
                  {tabContent[index]}
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
        <div className="row mt-3">
          <div className="col-md-9"></div>
          <div className="col-md-3">
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={getTotalItems()}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Recipe;
