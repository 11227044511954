import React, { useEffect, useState } from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import Chart from "react-apexcharts";
import Select from "react-select";
import "bootstrap-daterangepicker/daterangepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Masonry from 'masonry-layout';

import {
  dateOptions,
  AgeOptions,
  GAUserOptions,
  GADateOptions,
  GAEcommerceOptions,
  GAEventOptions,
  GARevenueOptions,
  GASessionOptions,
  GAPageScreenOptions,
  GAReportFirstUserOption,
  GAReportSessionOption,
  GAReportSessionmainOption,
  GAReportPlatformOption,
} from "./../../components/selectvalues/selectvalue.jsx";

import { ModalComponent } from "../expand/expandmodal.jsx";

const Garealtime = () => {
  const [chartOptions, setChartOptions] = useState(getChartOptions());
  const [sourceChartData, setSourceChartData] = useState([]);
  const [RealTimeDeviceTile, setDataRealtimeDeviceTile] = useState(null);
  
  const [RealTimeDeviceChartTile, setChartRealtimeDeviceTile] = useState([]);
  
  const [Nubaresponse, setNubaresponse] = useState([]);
  
  const [Vbptresponse, setVbptresponse] = useState([]);
  
  const [Ecenresponse, setEcenresponse] = useState([]);
  
  const [chartLabels, setChartLabels] = useState([]);

  const [dimensionMetrics, setDimensionMetrics] = useState([]);

  // console.log(sourceChartData);

  // console.log(RealTimeDeviceTile);
  // console.log(RealTimeDeviceChartTile)

  const Nubavalue =
    Nubaresponse.length > 0 ? Nubaresponse[0].metricValues[0].value : null;

  const Nubaheading =
    Nubaresponse.length > 0 ? Nubaresponse[0].dimensionValues[0].value : null;

  const Vbptvalue =
    Vbptresponse.length > 0 ? Vbptresponse[0].metricValues[0].value : null;

  const Vbptheading =
    Vbptresponse.length > 0 ? Vbptresponse[0].dimensionValues[0].value : null;

  const Ecenvalue =
    Ecenresponse.length > 0 ? Ecenresponse[0].metricValues[0].value : null;

  const Ecenheading =
    Ecenresponse.length > 0 ? Ecenresponse[0].dimensionValues[0].value : null;



    

  const nubalabel = Nubaresponse
    ? Nubaresponse.map((item) => item.dimensionValues?.[0]?.value || "Unknown")
    : [];
  const nubavalues = Nubaresponse
    ? Nubaresponse.map(
        (item) => parseInt(item.metricValues?.[0]?.value, 10) || 0
      )
    : [];


    const vbptlabel = Vbptresponse
    ? Vbptresponse.map((item) => item.dimensionValues?.[0]?.value || "Unknown").slice(0,10)
    : [];
  const vbptvalues = Vbptresponse
    ? Vbptresponse.map(
        (item) => parseInt(item.metricValues?.[0]?.value, 10) || 0
      ).slice(0,10)
    : [];



    const ecenlabel = Ecenresponse
    ? Ecenresponse.map((item) => item.dimensionValues?.[0]?.value || "Unknown")
    : [];
  const ecenvalues = Ecenresponse
    ? Ecenresponse.map(
        (item) => parseInt(item.metricValues?.[0]?.value, 10) || 0
      )
    : [];



    const vbptexpandlabel = Vbptresponse
    ? Vbptresponse.map((item) => item.dimensionValues?.[0]?.value || "Unknown")
    : [];
  const vbptexpandvalues = Vbptresponse
  ? Vbptresponse.map(
      (item) => parseInt(item.metricValues?.[0]?.value, 10) || 0
    )
  : [];




  var themeFont = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-family")
    .trim();
  var themeFontWeight = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-weight")
    .trim();
  var themeColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  var gray500 = getComputedStyle(document.body)
    .getPropertyValue("--bs-gray-500")
    .trim();
  var orange = getComputedStyle(document.body)
    .getPropertyValue("--bs-warning")
    .trim();
  var inverse = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse")
    .trim();
  var inverseRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse-rgb")
    .trim();
  var borderColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-border-color")
    .trim();

  var NubaChartOptions = {
    plotOptions: {
      bar: { horizontal: false, dataLabels: { position: "top" } },
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -20,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [themeColor],
    stroke: { show: true },
    // grid: { borderColor: borderColor },
    xaxis: {
      categories: nubalabel,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };


  var VbptChartOptions = {
    plotOptions: { bar: { horizontal: true, dataLabels: { position: "top" } } },
    dataLabels: {
      enabled: true,
      offsetX: 35,
      offsetY: 0,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [themeColor],
    stroke: { show: true },
    // grid: { borderColor: borderColor },
    xaxis: {
      categories: vbptlabel,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var EcenChartOptions = {
    plotOptions: { bar: { horizontal: true, dataLabels: { position: "top" } } },
    dataLabels: {
      enabled: true,
      offsetX: 35,
      offsetY: 0,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [themeColor],
    stroke: { show: true },
    // grid: { borderColor: borderColor },
    xaxis: {
      categories: ecenlabel,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var VbptExpandChartOptions = {
    plotOptions: { bar: { horizontal: true, dataLabels: { position: "top" } } },
    dataLabels: {
      enabled: true,
      offsetX: 35,
      offsetY: 0,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [themeColor],
    stroke: { show: true },
    // grid: { borderColor: borderColor },
    xaxis: {
      categories: vbptexpandlabel,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };




  var NubaChartData = [{ name: "Value", data: nubavalues }];

  var VbptChartData = [{ name: "Value", data: vbptvalues }];

  var EcenChartData = [{ name: "Value", data: ecenvalues }];

  var VbptExpandChartData = [{ name: "Value", data: vbptexpandvalues}];




  const ResponsiveChart = ({ options, series }) => {
    const [chartHeight, setChartHeight] = useState("50vh"); // Initial height

    useEffect(() => {
      const handleResize = () => {
        // Adjust the height based on the window size or any other logic
        setChartHeight(window.innerHeight * 0.5); // 50% of the viewport height
      };

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
      <div className="chart-container" style={{ height: chartHeight }}>
        <Chart
          type="bar"
          options={options}
          series={series}
          height={chartHeight}
        />
      </div>
    );
  };



  const VbptExpand = () => {
    return (
      <>
        <h3 className="mb-2">Views by Page title and screen name</h3>
       
        <div>
          {/* <h6 className="mt-4">User as Per Country</h6> */}
          <Chart
            type="bar"
            options={VbptExpandChartOptions}
            series={VbptExpandChartData}
            height={2500}
          />
        </div>
      </>
    );
  };

  // console.log(Nubavalue);

  function getChartOptions(labels = []) {
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    var themeColorRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme-rgb")
      .trim();

    return {
      donut: {
        colors: [
          "rgba(" + themeColorRgb + ", .95)",
          "rgba(" + themeColorRgb + ", .35)",
          "rgba(" + themeColorRgb + ", .55)",
          "rgba(" + themeColorRgb + ", .75)",
          "rgba(" + themeColorRgb + ", .95)",
        ],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
    };
  }

  useEffect(() => {
    const RealtimeDataInfo = async (dateoption) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ADDRESS}/ga4realtime/data/realtimeanalytics/realdata`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        // console.log(result)
        if (result) {
          setDataRealtimeDeviceTile(result.totalCountryActiveUser);
          setChartRealtimeDeviceTile(result.deviceresponse || []);
          setNubaresponse(result.nubaresponse || []);
          setVbptresponse(result.vbptresponse || []);
          setEcenresponse(result.ecenresponse || []);

          const chartData = result.deviceresponse.map(item => parseInt(item.metricValues[0].value));
          const chartLabels = result.deviceresponse.map(item => item.dimensionValues[0].value);

          setSourceChartData(chartData);
          setChartLabels(chartLabels);

          const dimensionMetrics = result.deviceresponse.map(item => ({
            dimension: item.dimensionValues[0].value,
            metric: item.metricValues[0].value,
          }));
          setDimensionMetrics(dimensionMetrics);


          setChartOptions(prevOptions => ({
            ...prevOptions,
            donut: {
              ...prevOptions.donut,
              labels: chartLabels
            }
          }));
        } else {
          console.log("No data returned from the API");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    RealtimeDataInfo();

    // Set up the interval to refresh the data every one minute
    const intervalId = setInterval(RealtimeDataInfo, 60000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // fetch("/assets/data/dashboard/source-chart.json")
    //   .then((res) => res.json())
    //   .then((result) => {
    //     setSourceChartData(result);
    //   });

    document.addEventListener("theme-reload", () => {
      setChartOptions(getChartOptions());
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    new Masonry('[data-masonry]');
  });

  return (
    <>
      <div className="row mb-3">
        <div className="col-xl-6 col-lg-6">
          <h6>
            <span style={{ color: "#3cd2a5" }}>PAGES</span> / GOOGLE ANALYTICS
          </h6>
          <h2>GOOGLE ANALYTICS REALTIMEDATA</h2>
        </div>
      </div>

      <div className="row" data-masonry='{"percentPosition": true }'>
        <div className="col-xl-4 col-lg-4 col-md-12 mb-3">
          <Card className="mb-3">
            <CardBody>
              <h6 className="mb-3">User in 30 minutes</h6>
              <div className="d-flex fw-bold small mb-2">
                <h2>{RealTimeDeviceTile}</h2>

                {/* <ModalComponent cardBodyContent={<EventExpand />} /> */}
              </div>
              <h5 className="mb-3">DEVICE CATEGORY IN LAST 30 MINUTES</h5>
              <div className="d-flex align-items-center mb-4">
                <div className="col-md-12 mt-3">
                  {sourceChartData && sourceChartData.length > 0 && (
                    <Chart
                      type="donut"
                      height="200"
                      options={chartOptions["donut"]}
                      series={sourceChartData}
                    />
                  )}
                </div>
              </div>
                <div className="d-flex justify-content-around align-items-center">
                {dimensionMetrics.map((item, index) => (
              <div key={index} className="text-center">
                {item.dimension.toUpperCase()}<br></br>{item.metric}
              </div>
            ))}
                </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-12 mb-3">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small">
                <div className="flex-grow-1 col-md-3 me-2">
                  <h6 className="col-md-7">Users by Audience</h6>
                </div>

                {/* <ModalComponent cardBodyContent={<SESSIONExpand />} /> */}
              </div>
              <h5 className="mt-2">#1 {Nubaheading}</h5>
              <h2 className="mt-2">{Nubavalue}</h2>
              <div className="mt-3">
                <ResponsiveChart
                  options={NubaChartOptions}
                  series={NubaChartData}
                />
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-12 mb-3">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small">
                <div className="flex-grow-1 col-md-3 me-2">
                  <h6 className="col-md-9">
                    Views by Page title and screen name
                  </h6>
                </div>

                <ModalComponent cardBodyContent={<VbptExpand />} />
              </div>
              <h5 className="mt-2">#1 {Vbptheading}</h5>
              <h2 className="mt-2">{Vbptvalue}</h2>
              <div className="mt-3">
              <ResponsiveChart
                  options={VbptChartOptions}
                  series={VbptChartData}
                />
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-12 mb-3">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small">
                <div className="flex-grow-1 col-md-3 me-2">
                  <h6 className="col-md-7">Event count by Event name</h6>
                </div>

                {/* <ModalComponent cardBodyContent={<SESSIONExpand />} /> */}
              </div>
              <h5 className="mt-2">#1 {Ecenheading}</h5>
              <h2 className="mt-2">{Ecenvalue}</h2>
              <div className="mt-3">
              <ResponsiveChart
                  options={EcenChartOptions}
                  series={EcenChartData}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Garealtime;
