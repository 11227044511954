import React, { useEffect, useState } from "react";

import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import { Card, CardBody } from "./../../../components/card/card.jsx";
import Chart from "react-apexcharts";
import Select from "react-select";
import "bootstrap-daterangepicker/daterangepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  dateOptions,
  AgeOptions,
  GAUserOptions,
  GADateOptions,
  GAEcommerceOptions,
  GAEventOptions,
  GARevenueOptions,
  GASessionOptions,
  GAPageScreenOptions,
  GAReportFirstUserOption,
  GAReportSessionOption,
  GAReportSessionmainOption,
  GAReportPlatformOption,
  CountryNames,
} from "./../../../components/selectvalues/selectvalue.jsx";

import { ModalComponent } from "./../../expand/expandmodal.jsx";

const Firebasereport = () => {
  // console.log(CountryNames)



  const [FirstFourUserTile, setDataUserTile] = useState({
    totalActiveUserValue: null,
    totalAverageEngagementTimeValues: null,
    totalNewUserValue: null,
    totalRevenueValues: null,
  });

  const [CountryTile, setCountryTile] = useState({ countrywise: null });
//   console.log(CountryTile);
  const [EventTile, setEventTile] = useState({ topeventresponse: null });

  const [PlatformTile, setProductTile] = useState({ platformresponse: null });

  const [SellingProductTile, setSellingProductTile] = useState({
    topsellingproductsresponse: null,
  });

  const [KeyEventTile, setKeyEventTile] = useState({
    topkeyeventsresponse: null,
  });

  const [PageScreenTile, setPageScreenTile] = useState({
    pageandscreenresponse: null,
  });

  const [SessioncfTile, setDataSessioncfTile] = useState({ session: null });

  // console.log(SessioncfTile)

  const [NucfTile, setDataNucfTile] = useState({ nucf: null });
  // console.log(NucfTile)

  const [RealTimerUserTile, setDataRealtimeUserTile] = useState({
    totalCountryActiveUser: null,
    countryWise: [],
  });
  const [RealTimeChartTile, setChartRealtimeUserTile] = useState([]);

  // console.log(FirstFourUserTile);
  // console.log(RealTimerUserTile);

  const [gauserselectedDateOption, setgauserSelectedDateOption] = useState(
    GADateOptions[4]
  );

  const [nucfselectedOption, setnucfSelectedOption] = useState(
    GAReportFirstUserOption[0]
  );

  const [sessioncfselectedOption, setSessioncfSelectedOption] = useState(
    GAReportSessionOption[0]
  );

  const [sessionmaincfselectedOption, setSessionmaincfSelectedOption] =
    useState(GAReportSessionmainOption[0]);

  const [platformselectedOption, setPlatformSelectedOption] = useState(
    GAReportPlatformOption[0]
  );

  const handlegauserdateChange = (selectedOption) => {
    setgauserSelectedDateOption(selectedOption);
  };

  const handlenucfChange = (selectedOption) => {
    setnucfSelectedOption(selectedOption);
  };

  const handleSessioncfChange = (selectedOption) => {
    setSessioncfSelectedOption(selectedOption);
  };

  const handleSessionmaincfChange = (selectedOption) => {
    setSessionmaincfSelectedOption(selectedOption);
  };

  const handlePlatformChange = (selectedOption) => {
    setPlatformSelectedOption(selectedOption);
  };

  const label = RealTimeChartTile
    ? RealTimeChartTile.map(
        (item) => item.dimensionValues?.[0]?.value || "Unknown"
      ).slice(0, 7)
    : [];
  const values = RealTimeChartTile
    ? RealTimeChartTile.map(
        (item) => parseInt(item.metricValues?.[0]?.value, 10) || 0
      ).slice(0, 7)
    : [];

  const nucflabel = NucfTile.nucf
    ? NucfTile.nucf
        .map((item) => item.dimensionValues?.[0]?.value || "Unknown")
        .slice(0, 10)
    : [];
  const nucfvalues = NucfTile.nucf
    ? NucfTile.nucf
        .map((item) => parseInt(item.metricValues?.[0]?.value, 10) || 0)
        .slice(0, 10)
    : [];

  const nucfexpandlabel = NucfTile.nucf
    ? NucfTile.nucf.map((item) => item.dimensionValues?.[0]?.value || "Unknown")
    : [];
  const nucfexpandvalues = NucfTile.nucf
    ? NucfTile.nucf.map(
        (item) => parseInt(item.metricValues?.[0]?.value, 10) || 0
      )
    : [];

  const sessioncflabel = SessioncfTile.session
    ? SessioncfTile.session
        .map((item) => item.dimensionValues?.[0]?.value || "Unknown")
        .slice(0, 10)
    : [];
  const sessioncfvalues = SessioncfTile.session
    ? SessioncfTile.session
        .map((item) => parseInt(item.metricValues?.[0]?.value, 10) || 0)
        .slice(0, 10)
    : [];

  const sessioncfexpandlabel = SessioncfTile.session
    ? SessioncfTile.session.map(
        (item) => item.dimensionValues?.[0]?.value || "Unknown"
      )
    : [];
  const sessioncfexpandvalues = SessioncfTile.session
    ? SessioncfTile.session.map(
        (item) => parseInt(item.metricValues?.[0]?.value, 10) || 0
      )
    : [];

  const pagescreenlabel = PageScreenTile.pageandscreenresponse
    ? PageScreenTile.pageandscreenresponse
        .map((item) => item.dimensionValues?.[0]?.value || "Unknown")
        .slice(0, 10)
    : [];
  const pagescreenvalues = PageScreenTile.pageandscreenresponse
    ? PageScreenTile.pageandscreenresponse
        .map((item) => parseInt(item.metricValues?.[0]?.value, 10) || 0)
        .slice(0, 10)
    : [];

  const keyeventlabel = KeyEventTile.topkeyeventsresponse
    ? KeyEventTile.topkeyeventsresponse
        .map((item) => item.dimensionValues?.[0]?.value || "Unknown")
        .slice(0, 10)
    : [];
  const keyeventvalues = KeyEventTile.topkeyeventsresponse
    ? KeyEventTile.topkeyeventsresponse
        .map((item) => parseInt(item.metricValues?.[0]?.value, 10) || 0)
        .slice(0, 10)
    : [];

  const sellingproductlabel = SellingProductTile.topsellingproductsresponse
    ? SellingProductTile.topsellingproductsresponse
        .map((item) => item.dimensionValues?.[0]?.value || "Unknown")
        .slice(0, 10)
    : [];
  const sellingproductvalues = SellingProductTile.topsellingproductsresponse
    ? SellingProductTile.topsellingproductsresponse
        .map((item) => parseInt(item.metricValues?.[0]?.value, 10) || 0)
        .slice(0, 10)
    : [];

  const platformlabel = PlatformTile.platformresponse
    ? PlatformTile.platformresponse
        .map((item) => item.dimensionValues?.[0]?.value || "Unknown")
        .slice(0, 10)
    : [];
  const platformvalues = PlatformTile.platformresponse
    ? PlatformTile.platformresponse
        .map((item) => parseInt(item.metricValues?.[0]?.value, 10) || 0)
        .slice(0, 10)
    : [];

  const eventlabel = EventTile.topeventresponse
    ? EventTile.topeventresponse
        .map((item) => item.dimensionValues?.[0]?.value || "Unknown")
        .slice(0, 10)
    : [];
  const eventvalues = EventTile.topeventresponse
    ? EventTile.topeventresponse
        .map((item) => parseInt(item.metricValues?.[0]?.value, 10) || 0)
        .slice(0, 10)
    : [];

  const eventexpandlabel = EventTile.topeventresponse
    ? EventTile.topeventresponse.map(
        (item) => item.dimensionValues?.[0]?.value || "Unknown"
      )
    : [];
  const eventexpandvalues = EventTile.topeventresponse
    ? EventTile.topeventresponse.map(
        (item) => parseInt(item.metricValues?.[0]?.value, 10) || 0
      )
    : [];

  const countrylabel = CountryTile.countrywise
    ? CountryTile.countrywise
        .map((item) => item.dimensionValues?.[0]?.value || "Unknown")
        .slice(0, 7)
    : [];
    
  const countryvalues = CountryTile.countrywise
    ? CountryTile.countrywise
        .map((item) => parseInt(item.metricValues?.[0]?.value, 10) || 0)
        .slice(0, 7)
    : [];

    function renderMap(countryData) {
        var inverse = getComputedStyle(document.body)
          .getPropertyValue("--bs-inverse")
          .trim();
        var themeColor = getComputedStyle(document.body)
          .getPropertyValue("--bs-theme")
          .trim();
        const map = document.getElementById("world-map");
        const mapElm = document.querySelectorAll(".jvm-tooltip");
    
        if (map) {
          for (let i = 0; i < mapElm.length; i++) {
            mapElm[i].remove();
          }
          map.innerHTML = "";
    
          new jsVectorMap({
            selector: "#world-map",
            map: "world",
            zoomButtons: true,
            normalizeFunction: "polynomial",
            hoverOpacity: 0.5,
            hoverColor: false,
            zoomOnScroll: true,
            series: {
                regions: [{
                    scale: ['#3CD2A5', '#3CD2A6', '#3CD2A7', '#3CD2A8', '#3CD2A9', '#3CD2B1', '#3CD2B2', '#3CD2B3', '#3CD2B4', '#3CD2B5'],// from lightest to darkest based on data range
                  normalizeFunction: 'polynomial',
                  values: countryData // Pass the dynamic data here
                }]
              },
            // labels: { markers: { render: (marker) => marker.name} },
            // focusOn: { x: 0.5, y: 0.5, scale: 1 },
            // markerStyle: {
            //   initial: { fill: themeColor, stroke: "none", r: 5 },
            //   hover: { fill: themeColor },
            // },
            regionStyle: {
              initial: {
                fill: inverse,
                fillOpacity: 0.85,
                stroke: "none",
                strokeWidth: 0.4,
                strokeOpacity: 0,
              },
              hover: { fill: themeColor, fillOpacity: 0.9},
            },
            backgroundColor: "transparent",
            onRegionTipShow: function (e, el, code) {
                if (countryData && countryData[code]) {
                    // If there is data for the country, show it with the country's name
                    el.html(`${el.html(e)} - ${countryData[code]} users`);
                } else {
                    // Handle cases where there is no data for a country
                    el.html(`${el.html(e)} - No data available`);
                }
            }
          });
        }
      }
    
      

    if (!CountryTile || !CountryTile.countrywise) {
        console.error('CountryTile data is not available');
        // Handle the lack of data appropriately, perhaps setting a loading state
        renderMap("Country");
    } else {
       
    const countryData = CountryTile.countrywise.reduce((acc, item) => {
        const countryName = item.dimensionValues?.[0]?.value || "Unknown";
        const countryCode = CountryNames[countryName];
        const userCount = parseInt(item.metricValues?.[0]?.value, 10) || 0;
    
        if (countryCode) {
            acc[countryCode] = (acc[countryCode] || 0) + userCount; // Accumulate counts if multiple entries per country
        }
    
        return acc;
    }, {});
    renderMap(countryData);
    // console.log(countryData);

    }


  var themeFont = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-family")
    .trim();
  var themeFontWeight = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-weight")
    .trim();
  var themeColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  var gray500 = getComputedStyle(document.body)
    .getPropertyValue("--bs-gray-500")
    .trim();
  var orange = getComputedStyle(document.body)
    .getPropertyValue("--bs-warning")
    .trim();
  var inverse = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse")
    .trim();
  var inverseRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse-rgb")
    .trim();
  var borderColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-border-color")
    .trim();

  // bar chart
  var barChartOptions = {
    plotOptions: {
      bar: { horizontal: false, dataLabels: { position: "top" } },
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -20,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [themeColor],
    stroke: { show: true },
    // grid: { borderColor: borderColor },
    xaxis: {
      categories: label,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var NucfChartOptions = {
    plotOptions: { bar: { horizontal: true, dataLabels: { position: "top" } } },
    dataLabels: {
      enabled: true,
      offsetX: 35,
      offsetY: 0,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [themeColor],
    stroke: { show: true },
    // grid: { borderColor: borderColor },
    xaxis: {
      categories: nucflabel,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var NucfExpandChartOptions = {
    plotOptions: { bar: { horizontal: true, dataLabels: { position: "top" } } },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [themeColor],
    stroke: { show: true },
    // grid: { borderColor: borderColor },
    xaxis: {
      categories: nucfexpandlabel,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var SessioncfChartOptions = {
    plotOptions: {
      bar: { horizontal: false, dataLabels: { position: "top" } },
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -20,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [themeColor],
    stroke: { show: true },
    // grid: { borderColor: borderColor },
    xaxis: {
      categories: sessioncflabel,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var SessioncfExpandChartOptions = {
    plotOptions: {
      bar: { horizontal: false, dataLabels: { position: "top" } },
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -20,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [themeColor],
    stroke: { show: true },
    // grid: { borderColor: borderColor },
    xaxis: {
      categories: sessioncfexpandlabel,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var PageScreenChartOptions = {
    plotOptions: { bar: { horizontal: true, dataLabels: { position: "top" } } },
    dataLabels: {
      enabled: true,
      offsetX: 35,
      offsetY: 0,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [themeColor],
    stroke: { show: true },
    // grid: { borderColor: borderColor },
    xaxis: {
      categories: pagescreenlabel,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var KeyEventChartOptions = {
    plotOptions: {
      bar: { horizontal: false, dataLabels: { position: "top" } },
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -20,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [themeColor],
    stroke: { show: true },
    // grid: { borderColor: borderColor },
    xaxis: {
      categories: keyeventlabel,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var SellingProductChartOptions = {
    plotOptions: {
      bar: { horizontal: false, dataLabels: { position: "top" } },
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -20,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [themeColor],
    stroke: { show: true },
    // grid: { borderColor: borderColor },
    xaxis: {
      categories: sellingproductlabel,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var PlatformChartOptions = {
    plotOptions: {
      bar: { horizontal: false, dataLabels: { position: "top" } },
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -20,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [themeColor],
    stroke: { show: true },
    // grid: { borderColor: borderColor },
    xaxis: {
      categories: platformlabel,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var EventChartOptions = {
    plotOptions: { bar: { horizontal: true, dataLabels: { position: "top" } } },
    dataLabels: {
      enabled: true,
      offsetX: 35,
      offsetY: 0,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [themeColor],
    stroke: { show: true },
    // grid: { borderColor: borderColor },
    xaxis: {
      categories: eventlabel,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var EventExpandChartOptions = {
    plotOptions: { bar: { horizontal: true, dataLabels: { position: "top" } } },
    dataLabels: {
      enabled: true,
      offsetX: 35,
      offsetY: 0,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [themeColor],
    stroke: { show: true },
    // grid: { borderColor: borderColor },
    xaxis: {
      categories: eventexpandlabel,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var CountryChartOptions = {
    plotOptions: { bar: { horizontal: true, dataLabels: { position: "top" } } },
    dataLabels: {
      enabled: true,
      offsetX: 35,
      offsetY: 0,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [themeColor],
    stroke: { show: true },
    // grid: { borderColor: borderColor },
    xaxis: {
      categories: countrylabel,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var barChartData = [{ name: "UserPerCountry", data: values }];

  var NucfChartData = [{ name: "values", data: nucfvalues }];

  var NucfExpandChartData = [{ name: "values", data: nucfexpandvalues }];

  var SessioncfChartData = [{ name: "values", data: sessioncfvalues }];

  var SessioncfExpandChartData = [
    { name: "values", data: sessioncfexpandvalues },
  ];

  var PageScreenChartData = [{ name: "values", data: pagescreenvalues }];

  var KeyEventChartData = [{ name: "values", data: keyeventvalues }];

  var SellingProductChartData = [
    { name: "values", data: sellingproductvalues },
  ];

  var PlatformChartData = [{ name: "values", data: platformvalues }];

  var EventChartData = [{ name: "values", data: eventvalues }];

  var EventExpandChartData = [{ name: "values", data: eventexpandvalues }];

  var CountryChartData = [{ name: "values", data: countryvalues }];

  const NUCFExpand = () => {
    return (
      <>
        <h2>WHERE DO YOUR NEW USERS COME FROM?</h2>
        <h3>New User by{}</h3>
        <div className="row">
          {/* {data && <h2 className="col-xl-3">{data.make}</h2>} */}
          <h2 className="col-xl-3">
            {/* {UserTile.totalMetricValue
              } */}
          </h2>

          <div className="col-md-2">
            <label htmlFor="age">User Options</label>
            <Select
              options={GAReportFirstUserOption}
              value={nucfselectedOption}
              onChange={handlenucfChange}
              classNamePrefix="react-select"
            />
          </div>

          <div className="col-md-2">
            <label htmlFor="date">Date</label>
            <Select
              options={GADateOptions}
              value={gauserselectedDateOption}
              onChange={handlegauserdateChange}
              classNamePrefix="react-select"
            />
          </div>

          <div className="col-md-2 mt-3 pt-1">
            <button className="btn btn-secondary">
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
        <div>
          {/* <h6 className="mt-4">User as Per Country</h6> */}
          <Chart
            type="bar"
            options={NucfExpandChartOptions}
            series={NucfExpandChartData}
            height={750}
          />
        </div>
      </>
    );
  };

  const SESSIONExpand = () => {
    return (
      <>
        <h3 className="mb-2">WHAT ARE YOUR TOP CAMPAIGNS?</h3>
        <div className="row">
          {/* {data && <h2 className="col-xl-3">{data.make}</h2>} */}

          <div className="col-md-3">
            <label htmlFor="date">Date</label>
            <Select
              options={GADateOptions}
              value={gauserselectedDateOption}
              onChange={handlegauserdateChange}
              classNamePrefix="react-select"
            />
          </div>

          <div className="col-md-3">
            <label htmlFor="age">Session Option</label>
            <Select
              options={GAReportSessionmainOption}
              value={sessionmaincfselectedOption}
              onChange={handleSessionmaincfChange}
              classNamePrefix="react-select"
            />
          </div>

          <div className="col-md-1 mt-4">
            <h3>by</h3>
          </div>

          <div className="col-md-5">
            <label htmlFor="age">User Options</label>
            <Select
              options={GAReportSessionOption}
              value={sessioncfselectedOption}
              onChange={handleSessioncfChange}
              classNamePrefix="react-select"
            />
          </div>

          {/* <div className="col-md-2 mt-3 pt-1">
            <button className="btn btn-secondary">
              <i className="fas fa-search"></i>
            </button>
          </div> */}
        </div>
        <div>
          {/* <h6 className="mt-4">User as Per Country</h6> */}
          <Chart
            type="bar"
            options={SessioncfExpandChartOptions}
            series={SessioncfExpandChartData}
            height={750}
          />
        </div>
      </>
    );
  };

  const EventExpand = () => {
    return (
      <>
        <h2>WHAT ARE YOUR TOP EVENTS?</h2>
        <h3>Event count by Event name</h3>
        <div className="row">
          {/* {data && <h2 className="col-xl-3">{data.make}</h2>} */}
          <h2 className="col-xl-3">
            {/* {UserTile.totalMetricValue
              } */}
          </h2>

          {/* <div className="col-md-2">
            <label htmlFor="age">User Options</label>
            <Select
              options={GAReportFirstUserOption}
              value={nucfselectedOption}
              onChange={handlenucfChange}
              classNamePrefix="react-select"
            />
          </div>

          <div className="col-md-2">
            <label htmlFor="date">Date</label>
            <Select
              options={GADateOptions}
              value={gauserselectedDateOption}
              onChange={handlegauserdateChange}
              classNamePrefix="react-select"
            />
          </div> */}

          {/* <div className="col-md-2 mt-3 pt-1">
            <button className="btn btn-secondary">
              <i className="fas fa-search"></i>
            </button>
          </div> */}
        </div>
        <div>
          {/* <h6 className="mt-4">User as Per Country</h6> */}
          <Chart
            type="bar"
            options={EventExpandChartOptions}
            series={EventExpandChartData}
            height={750}
          />
        </div>
      </>
    );
  };

  const ResponsiveChart = ({ options, series }) => {
    const [chartHeight, setChartHeight] = useState("50vh"); // Initial height

    useEffect(() => {
      const handleResize = () => {
        // Adjust the height based on the window size or any other logic
        setChartHeight(window.innerHeight * 0.5); // 50% of the viewport height
      };

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
      <div className="chart-container" style={{ height: chartHeight }}>
        <Chart
          type="bar"
          options={options}
          series={series}
          height={chartHeight}
        />
      </div>
    );
  };

  useEffect(() => {
    const UserTileInfo = async (dateoption, nucf) => {
      try {
        let startDate = gauserselectedDateOption.value;
        let endDate = "today";
        let dimension = "date";
        let nucfdimension = nucfselectedOption.value;
        let sessioncf = sessionmaincfselectedOption.value;
        let sessioncfdimension = sessioncfselectedOption.value;
        let platformmetrics = platformselectedOption.value;
        // console.log(startDate, metric, "YES WE GET");
        // console.log(sessioncf, sessioncfdimension);
        const response = await fetch(
          `${process.env.REACT_APP_API_ADDRESS}/firebasega4report/data/reportanalytics/dataset?startDate=${startDate}&endDate=${endDate}&dimension=${dimension}&nucfdimension=${nucfdimension}&sessioncf=${sessioncf}&sessioncfdimension=${sessioncfdimension}&platformmetrics=${platformmetrics}`
        );
        const result = await response.json();
        // console.log(result);

        // const sortedData = sortDataByDateDescending(result.dailyData);

        setDataUserTile({
          totalActiveUserValue: result.total.totalActiveUserValue,
          totalAverageEngagementTimeValues: result.AverageEngagementTime,
          totalNewUserValue: result.total.totalNewUserValue,
          totalRevenueValues: result.total.totalRevenueValues,
        });
        setCountryTile({ countrywise: result.countrywise || [] });
        setDataNucfTile({ nucf: result.nucfdata || [] });
        setDataSessioncfTile({ session: result.session || [] });
        setPageScreenTile({
          pageandscreenresponse: result.pageandscreenresponse || [],
        });
        setKeyEventTile({
          topkeyeventsresponse: result.topkeyeventsresponse || [],
        });
        setSellingProductTile({
          topsellingproductsresponse: result.topsellingproductsresponse || [],
        });
        setProductTile({ platformresponse: result.platformresponse || [] });
        setEventTile({ topeventresponse: result.topeventresponse || [] });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    UserTileInfo(
      gauserselectedDateOption.value,
      nucfselectedOption.value,
      sessionmaincfselectedOption.value,
      sessioncfselectedOption.value,
      platformselectedOption.value
    );
  }, [
    gauserselectedDateOption,
    nucfselectedOption,
    sessionmaincfselectedOption,
    sessioncfselectedOption,
    platformselectedOption,
  ]);

  useEffect(() => {
    const RealtimeUserTileInfo = async (dateoption) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ADDRESS}/firebasega4report/data/reportanalytics/realtimeuser`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        if (result) {
          setDataRealtimeUserTile(result);
          setChartRealtimeUserTile(result.countryWise || []);
        } else {
          console.log("No data returned from the API");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    RealtimeUserTileInfo();

    // Set up the interval to refresh the data every one minute
    const intervalId = setInterval(RealtimeUserTileInfo, 60000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="row mb-3">
        <div className="col-xl-6 col-lg-6">
          <h6>
            <span style={{ color: "#3cd2a5" }}>PAGES</span> / FIREBASE ANALYTICS
          </h6>
          <h2>FIREBASE ANALYTICS REPORT</h2>
        </div>
        <div className="col-xl-3 col-lg-6">
          <h5>Filter</h5>
          <Select
            options={GADateOptions}
            value={gauserselectedDateOption}
            onChange={handlegauserdateChange}
            classNamePrefix="react-select"
          />
        </div>
      </div>

      <div className="row">
      
        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">{"USERS"}</span>
                {/* <ModalComponent cardBodyContent={TotalUserExpand} /> */}
              </div>
              <div className="row align-items-center mb-4 mt-4">
                <div className="col-12 mt-3 mb-3">
                  <h3 className="mb-0">
                    {FirstFourUserTile &&
                    FirstFourUserTile.totalActiveUserValue ? (
                      FirstFourUserTile.totalActiveUserValue
                    ) : (
                      <div className="spinner-border text-success"></div>
                    )}
                    {/* 13000 */}
                  </h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">{"New Users"}</span>
                {/* <ModalComponent cardBodyContent={TotalUserExpand} /> */}
              </div>
              <div className="row align-items-center mb-4 mt-4">
                <div className="col-12 mt-3 mb-3">
                  <h3 className="mb-0">
                    {FirstFourUserTile &&
                    FirstFourUserTile.totalNewUserValue ? (
                      FirstFourUserTile.totalNewUserValue
                    ) : (
                      <div className="spinner-border text-success"></div>
                    )}
                    {/* 14000 */}
                  </h3>
                </div>
                {/* <div className="col-7 mt-3 mb-3"> */}
                {/* {statsData &&
                    statsData.length > 0 &&
                    statsData
                      .filter((stat, index) => index === 0)
                      .map((stat, index) => (
                        <Chart
                          type={stat.chartType}
                          height={stat.chartHeight}
                          options={chartOptions[stat.chartType]}
                          series={TRUbarChartData}
                          key={index}
                        />
                      ))} */}
                {/* </div> */}
              </div>
              {/* <div className="small text-inverse text-opacity-50 text-truncate">
                {stat.info.length > 0 &&
                      stat.info.map((info, index) => (
                        <div key={index}>
                          <i className={info.icon}></i> {info.text}
                        </div>
                      ))}
              </div> */}
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">{"Average Engagement Time"}</span>
                {/* <ModalComponent cardBodyContent={TotalUserExpand} /> */}
              </div>
              <div className="row align-items-center mb-4 mt-4">
                <div className="col-12 mt-3 mb-3">
                  <h3 className="mb-0">
                    {FirstFourUserTile &&
                    FirstFourUserTile.totalAverageEngagementTimeValues ? (
                      FirstFourUserTile.totalAverageEngagementTimeValues
                    ) : (
                      <div className="spinner-border text-success"></div>
                    )}
                    {/* 26s */}
                  </h3>
                </div>
                {/* <div className="col-7 mt-3 mb-3"> */}
                {/* {statsData &&
                    statsData.length > 0 &&
                    statsData
                      .filter((stat, index) => index === 0)
                      .map((stat, index) => (
                        <Chart
                          type={stat.chartType}
                          height={stat.chartHeight}
                          options={chartOptions[stat.chartType]}
                          series={TRUbarChartData}
                          key={index}
                        />
                      ))} */}
                {/* </div> */}
              </div>
              {/* <div className="small text-inverse text-opacity-50 text-truncate">
                {stat.info.length > 0 &&
                      stat.info.map((info, index) => (
                        <div key={index}>
                          <i className={info.icon}></i> {info.text}
                        </div>
                      ))}
              </div> */}
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">{"Total Revenue"}</span>
                {/* <ModalComponent cardBodyContent={TotalUserExpand} /> */}
              </div>
              <div className="row align-items-center mb-4 mt-4">
                <div className="col-12 mt-3 mb-3">
                  <h3 className="mb-0">
                    {FirstFourUserTile && FirstFourUserTile.totalRevenueValues
                      ? "$" + FirstFourUserTile.totalRevenueValues
                      : 0}
                    {/* $96.00 */}
                  </h3>
                </div>
                {/* <div className="col-7 mt-3 mb-3"> */}
                {/* {statsData &&
                    statsData.length > 0 &&
                    statsData
                      .filter((stat, index) => index === 0)
                      .map((stat, index) => (
                        <Chart
                          type={stat.chartType}
                          height={stat.chartHeight}
                          options={chartOptions[stat.chartType]}
                          series={TRUbarChartData}
                          key={index}
                        />
                      ))} */}
                {/* </div> */}
              </div>
              {/* <div className="small text-inverse text-opacity-50 text-truncate">
                {stat.info.length > 0 &&
                      stat.info.map((info, index) => (
                        <div key={index}>
                          <i className={info.icon}></i> {info.text}
                        </div>
                      ))}
              </div> */}
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">
                  {"Users in last 30 Minutes"}
                </span>
                {/* <ModalComponent cardBodyContent={TotalUserExpand} /> */}
              </div>
              <div className="row align-items-center mb-1 ">
                <div className="col-12 mt-1 mb-0">
                  <h3 className="mb-0">
                    {RealTimerUserTile &&
                    RealTimerUserTile.totalCountryActiveUser ? (
                      RealTimerUserTile.totalCountryActiveUser
                    ) : (
                      <div className="spinner-border text-success"></div>
                    )}
                    {/* 23 */}
                  </h3>
                </div>
              </div>

              <div>
                <h6 className="mt-4">User as Per Country</h6>
                <Chart
                  type="bar"
                  options={barChartOptions}
                  series={barChartData}
                  height="427"
                />
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-6 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <h6 className="mb-3">WHERE DO YOUR NEW USERS COME FROM?</h6>
              <div className="d-flex fw-bold small mb-3">
                <h4 className="flex-grow-1 col-md-3">{"New Users by"}</h4>
                <div className="flex-grow-1 col-md-7 me-4">
                  {/* <label htmlFor="age">User Options</label> */}
                  <Select
                    options={GAReportFirstUserOption}
                    value={nucfselectedOption}
                    onChange={handlenucfChange}
                    classNamePrefix="react-select"
                  />
                </div>
                <ModalComponent cardBodyContent={<NUCFExpand />} />
              </div>
              <div>
                {/* <h6 className="mt-4">User as Per Country</h6> */}
                <Chart
                  type="bar"
                  options={NucfChartOptions}
                  series={NucfChartData}
                  height="461"
                />
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <h6 className="mb-3">WHAT ARE YOUR TOP CAMPAIGNS? {}</h6>
              <div className="d-flex fw-bold small">
                {/* <span className="flex-grow-1">
                  {"Users in last 30 Minutes"}
                </span> */}
                <div className="flex-grow-1 col-md-3 me-2">
                  <Select
                    options={GAReportSessionmainOption}
                    value={sessionmaincfselectedOption}
                    onChange={handleSessionmaincfChange}
                    classNamePrefix="react-select"
                  />
                </div>
                <h6 className="col-md-1 mt-1">by</h6>
                <div className="flex-grow-1 col-md-7 me-2">
                  <Select
                    options={GAReportSessionOption}
                    value={sessioncfselectedOption}
                    onChange={handleSessioncfChange}
                    classNamePrefix="react-select"
                  />
                </div>
                <ModalComponent cardBodyContent={<SESSIONExpand />} />
              </div>
              <div className="mt-3">
                <Chart
                  type="bar"
                  options={SessioncfChartOptions}
                  series={SessioncfChartData}
                  height="461"
                />
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-6 col-lg-12 col-md-12 mb-3">
          <Card className="mb-3">
            <CardBody>
              <h6 className="mb-3">Country</h6>
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div className="ratio ratio-21x9 mb-4">
                    <div
                      id="world-map"
                      className="jvectormap-without-padding"
                    ></div>
                  </div>
                </div>
                <div className="col-md-12">
                  <Chart
                    type="bar"
                    options={CountryChartOptions}
                    series={CountryChartData}
                    height="160"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-12 mb-3">
          <Card className="mb-3">
            <CardBody>
              <h6 className="mb-3">
                WHICH PAGES AND SCREENS GET THE MOST VIEWS?
              </h6>
              <div className="d-flex fw-bold small mb-3">
                <h6 className="flex-grow-1">
                  Views by Page title and screen class
                </h6>
                {/* <ModalComponent cardBodyContent={<NUCFExpand />} /> */}
              </div>
              <div className="mt-3">
                <ResponsiveChart
                  options={PageScreenChartOptions}
                  series={PageScreenChartData}
                />
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-12 mb-3">
          <Card className="mb-3">
            <CardBody>
              <h6 className="mb-3">WHAT ARE YOUR TOP PERFORMING KEY EVENTS?</h6>
              <div className="d-flex fw-bold small mb-3">
                <h6 className="flex-grow-1">Key events by Event name</h6>
                {/* <ModalComponent cardBodyContent={<NUCFExpand />} /> */}
              </div>
              <div className="mt-3">
                <ResponsiveChart
                  options={KeyEventChartOptions}
                  series={KeyEventChartData}
                />
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-12 mb-3">
          <Card className="mb-3">
            <CardBody>
              <h6 className="mb-3">WHAT ARE YOUR TOP SELLING PRODUCTS?</h6>
              <div className="d-flex fw-bold small mb-3">
                <h6 className="flex-grow-1">Items purchased by Item name</h6>
                {/* <ModalComponent cardBodyContent={<NUCFExpand />} /> */}
              </div>
              <div className="mt-3">
                <Chart
                  type="bar"
                  options={SellingProductChartOptions}
                  series={SellingProductChartData}
                  height="461"
                />
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-12 mb-3">
          <Card className="mb-3">
            <CardBody>
              <h6 className="mb-3">
                HOW DOES ACTIVITY ON YOUR PLATFORMS COMPARE?
              </h6>
              <div className="d-flex fw-bold small">
                {/* <span className="flex-grow-1">
                  {"Users in last 30 Minutes"}
                </span> */}
                <div className="flex-grow-1 col-md-3 me-2">
                  <Select
                    options={GAReportPlatformOption}
                    value={platformselectedOption}
                    onChange={handlePlatformChange}
                    classNamePrefix="react-select"
                  />
                </div>
                <h5 className="col-md-7 mt-1">by Platform</h5>

                {/* <ModalComponent cardBodyContent={<SESSIONExpand />} /> */}
              </div>
              <div className="mt-3">
                <Chart
                  type="bar"
                  options={PlatformChartOptions}
                  series={PlatformChartData}
                  height="432"
                />
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12 mb-3">
          <Card className="mb-3">
            <CardBody>
              <h6 className="mb-3">WHAT ARE YOUR TOP EVENTS?</h6>
              <div className="d-flex fw-bold small mb-2">
                <h5 className="flex-grow-1 col-md-3">
                  {"Event count by Event name"}
                </h5>

                <ModalComponent cardBodyContent={<EventExpand />} />
              </div>
              <div>
                {/* <h6 className="mt-4">User as Per Country</h6> */}
                <Chart
                  type="bar"
                  options={EventChartOptions}
                  series={EventChartData}
                  height="461"
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Firebasereport;
