import React, { useState, useEffect } from 'react';
import { Form, Image } from 'react-bootstrap';
import { Card, CardBody } from './../../components/card/card.jsx';
import Select from 'react-select';
import { dateOptions } from './../../components/selectvalues/selectvalue.jsx';
import glassshimmer from '../../assets/glassshimmer.png';
import DatePicker from 'react-datepicker';
import Pagination from "./../../components/pagination/Pagination.jsx"; // Import the Pagination component

const Partners = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [currentTab, setCurrentTab] = useState('viewed');
  const [isLoading, setIsLoading] = useState(true);

  const [selectedTable, setSelectedTable] = useState(0); // Default to table 0

  var currentDate = new Date();
  var timestampInSeconds = Math.floor(currentDate.getTime() / 1000);

  const [PartnerViewTile, setPartnerViewTile] = useState([]);
  const [PartnerClickedTile, setPartnerClickedTile] = useState([]);
  const [PartnerSharedTile, setPartnerSharedTile] = useState([]);

  const [PartnerselectedDateOption, setPartnerSelectedDateOption] = useState(dateOptions[1]);
  const [PartnercustomDateRange, setPartnerCustomDateRange] = useState(false);
  const [PartnerstartDate, setPartnerStartDate] = useState(new Date());
  const [PartnerendDate, setPartnerEndDate] = useState(new Date());

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const PartnerviewcurrentItems = PartnerViewTile.slice(indexOfFirstItem, indexOfLastItem);
  const PartnerclickedcurrentItems = PartnerClickedTile.slice(indexOfFirstItem, indexOfLastItem);
  const PartnersharedcurrentItems = PartnerSharedTile.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const producttabs = [
    { id: 'viewed', label: 'Viewed', active: true },
    { id: 'clicked', label: 'Clicked', active: false },
    { id: 'shared', label: 'Shared', active: false },
  ];

  const tabPartner = [
    <div className="table-responsive ">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : PartnerviewcurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        PartnerviewcurrentItems.map((product, index) => (
          <div key={index} className={'mb-3 p-3 product_border'}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <Image
                      src={`${product.image ? product.image : glassshimmer}`}
                      className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-80px"
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">{product.name}</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div className={index < PartnerViewTile.length - 1 ? 'mb-2' : ''}>
                  <div className="mt-3">
                    <label>Views:</label>
                    <span className="ms-3" style={{ color: '#3cd2a5' }}>
                      {product.count}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
    <div className="table-responsive ">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : PartnerclickedcurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        PartnerclickedcurrentItems.map((product, index) => (
          <div key={index} className={'mb-3 p-3 product_border'}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <Image
                      src={`${product.image ? product.image : glassshimmer}`}
                      className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-80px"
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">{product.name}</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div className={index < PartnerClickedTile.length - 1 ? 'mb-2' : ''}>
                  <div className="mt-3">
                    <label>Clicks :</label>
                    <span className="ms-3" style={{ color: '#3cd2a5' }}>
                      {product.count}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
    <div className="table-responsive ">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : PartnersharedcurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        PartnersharedcurrentItems.map((product, index) => (
          <div key={index} className={'mb-3 p-3 product_border'}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <Image
                      src={`${product.image ? product.image : glassshimmer}`}
                      className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-80px"
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">{product.name}</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div className={index < PartnerSharedTile.length - 1 ? 'mb-2' : ''}>
                  <div className="mt-3">
                    <label>Shares:</label>
                    <span className="ms-3" style={{ color: '#3cd2a5' }}>
                      {product.qty}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
  ];

  useEffect(() => {
    const endTimestamp = timestampInSeconds;
    const startTimestamp = endTimestamp - 7 * 24 * 60 * 60; // 7 days in seconds
    const defaultSelectedOption = dateOptions[1]; // Weekly by default

    setPartnerSelectedDateOption(defaultSelectedOption);
    setPartnerStartDate(new Date(startTimestamp * 1000)); // Convert seconds to milliseconds
    setPartnerEndDate(new Date(endTimestamp * 1000)); // Convert seconds to milliseconds

    PartnerTileInfo(getFilterValue(currentTab), startTimestamp, endTimestamp);
  }, [currentTab]);

  const dateToTimestampInSeconds = (date) => {
    return Math.floor(date.getTime() / 1000);
  };

  const handleDateOptionChangePartner = (selectedOption) => {
    setPartnerSelectedDateOption(selectedOption);
    if (selectedOption.value === 3) {
      setPartnerCustomDateRange(true);
    } else {
      setPartnerCustomDateRange(false);
      const endTimestamp = timestampInSeconds;
      let startTimestamp;
      switch (selectedOption.value) {
        case 5: // Daily
          startTimestamp = endTimestamp - 24 * 60 * 60; // 1 day in seconds
          break;
        case 2: // Weekly
          startTimestamp = endTimestamp - 7 * 24 * 60 * 60; // 7 days in seconds
          break;
        case 1: // Monthly
          const currentDate = new Date();
          startTimestamp = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getTime() / 1000;
          break;
        case 0: // Yearly
          startTimestamp = new Date(new Date().getFullYear(), 0, 1).getTime() / 1000;
          break;
        default:
          return;
      }

      setPartnerStartDate(new Date(startTimestamp * 1000)); // Convert seconds to milliseconds
      setPartnerEndDate(new Date(endTimestamp * 1000)); // Convert seconds to milliseconds
      PartnerTileInfo(getFilterValue(currentTab), startTimestamp, endTimestamp);
    }
  };

  const handleCustomDateRangeChange = (startDate, endDate) => {
    const startTimestamp = dateToTimestampInSeconds(startDate);
    const endTimestamp = dateToTimestampInSeconds(endDate);
    PartnerTileInfo(getFilterValue(currentTab), startTimestamp, endTimestamp);
  };

  const PartnerTileInfo = async (
    filterValue,
    startTime = null,
    endTime = null,
    table = 0
  ) => {
    setIsLoading(true);
    try {
      if (!startTime || !endTime) {
        // Custom Date Range
        startTime = dateToTimestampInSeconds(PartnerstartDate);
        endTime = dateToTimestampInSeconds(PartnerendDate);
      }
      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/partnerpageinfo?startTime=${startTime}&endTime=${endTime}&filter=${filterValue}&table=${table}`
      );
      const result = await response.json();
      // console.log(result.userDetails);
      switch (filterValue) {
        case 0:
          setPartnerViewTile(result.userDetails || []); // Set to empty array if undefined
          break;
        case 1:
          setPartnerClickedTile(result.userDetails || []); // Set to empty array if undefined
          break;
        case 2:
          setPartnerSharedTile(result.userDetails || []); // Set to empty array if undefined
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Ensures loading is turned off whether fetch succeeds or fails
    }
  };
  

  const getFilterValue = (tab) => {
    switch (tab) {
      case 'viewed':
        return 0;
      case 'clicked':
        return 1;
      case 'shared':
        return 2;
      default:
        return 0;
    }
  };

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
    setSelectedTable(0);
    PartnerTileInfo(getFilterValue(tab));
    setPartnerCustomDateRange(false);
  };

  const handleTableChange = (event) => {
    setSelectedTable(parseInt(event.target.value));
    PartnerTileInfo(getFilterValue(currentTab), undefined, undefined, parseInt(event.target.value));
  };

  return (
    <>
      <div>
        <h6>
          <span style={{ color: '#3cd2a5' }}>PAGES</span> / PARTNERS
        </h6>
        <div className="row">
          <div className="col-xl-8">
            <h1>PARTNERS</h1>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10 pt-3">
                <ul className="nav nav-underline" id="myTab" role="tablist">
                  {producttabs.map((tab, index) => (
                    <li className="nav-item" role="presentation" key={index}>
                      <button
                        className={`nav-link ${tab.active ? 'active' : ''}`}
                        id={`${tab.id}-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#${tab.id}`}
                        type="button"
                        role="tab"
                        aria-controls={tab.id}
                        aria-selected={tab.active ? 'true' : 'false'}
                        onClick={() => handleTabChange(tab.id)}
                      >
                        {tab.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-8">
          <Form>
            <Form.Group controlId="formInput">
              <div className="row">
                <div className="col-md-2">
                  <label htmlFor="date">Date</label>
                  <Select
                    options={dateOptions}
                    value={PartnerselectedDateOption}
                    onChange={handleDateOptionChangePartner}
                    classNamePrefix="react-select"
                  />
                  <div className="col-xl-9">
                    {PartnercustomDateRange && (
                      <>
                        <label>From:</label>
                        <DatePicker
                          selected={PartnerstartDate}
                          onChange={(date) => {
                            setPartnerStartDate(date);
                            handleCustomDateRangeChange(date, PartnerendDate);
                          }}
                          className="form-control"
                        />
                        <br />
                        <label>To:</label>
                        <DatePicker
                          selected={PartnerendDate}
                          onChange={(date) => {
                            setPartnerEndDate(date);
                            handleCustomDateRangeChange(PartnerstartDate, date);
                          }}
                          className="form-control"
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-2 mt-3 pt-1">
                  <Form.Control type="text" value="" onChange="" placeholder="Search" />
                </div>

                <div className="col-md-8 mt-3 pt-1">
                  <button className="btn btn-secondary">
                    <i className="fas fa-search"></i>
                  </button>
                  &nbsp; &nbsp;
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="table"
                      id="table-0"
                      value={0}
                      checked={selectedTable === 0}
                      onChange={handleTableChange}
                    />
                    <label className="form-check-label" htmlFor="table-0">
                      Mixologists
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="table"
                      id="table-1"
                      value={1}
                      checked={selectedTable === 1}
                      onChange={handleTableChange}
                    />
                    <label className="form-check-label" htmlFor="table-1">
                      Brands
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="table"
                      id="table-2"
                      value={2}
                      checked={selectedTable === 2}
                      onChange={handleTableChange}
                    />
                    <label className="form-check-label" htmlFor="table-2">
                      Influencers
                    </label>
                  </div>
                </div>
              </div>
            </Form.Group>
          </Form>
        </div>
      </div>

      <div className="col-xl-12">
        <Card className="mt-3">
          <CardBody>
            <div className="tab-content" id="myTabContent">
              {producttabs.map((tab, index) => (
                <div
                  className={`tab-pane fade ${tab.active ? 'show active' : ''}`}
                  id={tab.id}
                  role="tabpanel"
                  aria-labelledby={`${tab.id}-tab`}
                  key={index}
                >
                  {tabPartner[index]}
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
        <div className="row mt-3">
          <div className="col-md-10"></div>
          <div className="col-md-2">
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={
                PartnerViewTile.length || PartnerClickedTile.length || PartnerSharedTile.length
              }
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners;
