import React, { useState, useEffect } from "react";
import { Form, Image } from "react-bootstrap";
import { Card, CardBody } from "./../../components/card/card.jsx";
import Select from "react-select";
import { dateOptions } from "./../../components/selectvalues/selectvalue.jsx";
import glassshimmer from "../../assets/glassshimmer.png";
import DatePicker from "react-datepicker";
import Pagination from "./../../components/pagination/Pagination.jsx"; // Import the Pagination component

const Content = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [currentTab, setCurrentTab] = useState("viewed");
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(""); // Add search term state

  const [selectedTable, setSelectedTable] = useState(0); // Default to table 0

  var currentDate = new Date();
  // Convert the current date to a timestamp in seconds
  var timestampInSeconds = Math.floor(currentDate.getTime() / 1000);

  const [ContentViewTile, setContentViewTile] = useState([]); // Contents Page View
  const [ContentClickedTile, setContentClickedTile] = useState([]); // Contents Click
  const [ContentSharedTile, setContentSharedTile] = useState([]); // Contents Shared

  const [ContentselectedDateOption, setContentSelectedDateOption] = useState(
    dateOptions[1]
  );
  const [ContentcustomDateRange, setContentCustomDateRange] = useState(false);
  const [ContentstartDate, setContentStartDate] = useState(new Date());
  const [ContentendDate, setContentEndDate] = useState(new Date());

  // Logic to get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Filter items based on search term
  const getFilteredItems = (items) => {
    return items.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const ContentviewcurrentItems = getFilteredItems(ContentViewTile).slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const ContentclickedcurrentItems = getFilteredItems(ContentClickedTile).slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const ContentsharedcurrentItems = getFilteredItems(ContentSharedTile).slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const producttabs = [
    { id: "viewed", label: "Viewed", active: true },
    { id: "clicked", label: "Clicked", active: false },
    { id: "shared", label: "Shared", active: false },
  ];

  const tabContent = [
    <div className="table-responsive ">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : ContentviewcurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        ContentviewcurrentItems.map((product, index) => (
          <div key={index} className={"mb-3 p-3 product_border"}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <Image
                      src={`${product.image ? product.image : glassshimmer}`}
                      className="object-fit-cover w-80px h-80px"
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">{product.name}</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div
                  className={index < ContentViewTile.length - 1 ? "mb-2" : ""}
                >
                  <div className="mt-3">
                    <label>Views:</label>
                    <span className="ms-3" style={{ color: "#3cd2a5" }}>
                      {product.count}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
    <div className="table-responsive ">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : ContentclickedcurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        ContentclickedcurrentItems.map((product, index) => (
          <div key={index} className={"mb-3 p-3 product_border"}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <Image
                      src={`${product.image ? product.image : glassshimmer}`}
                      className="object-fit-cover w-80px h-80px"
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">{product.name}</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div
                  className={
                    index < ContentClickedTile.length - 1 ? "mb-2" : ""
                  }
                >
                  <div className="mt-3">
                    <label>Clicks:</label>
                    <span className="ms-3" style={{ color: "#3cd2a5" }}>
                      {product.count}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
    <div className="table-responsive ">
      {isLoading ? (
        <div className="spinner-border text-success"></div>
      ) : ContentsharedcurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        ContentsharedcurrentItems.map((product, index) => (
          <div key={index} className={"mb-3 p-3 product_border"}>
            <div className="row ">
              <div className="col-xl-8 col-12 col-md-6 col">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px bg-theme text-theme-900">
                    {indexOfFirstItem + index + 1}
                  </div>
                  <div className="position-relative">
                    <Image
                      src={`${product.image ? product.image : glassshimmer}`}
                      className="object-fit-cover w-80px h-80px"
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">{product.name}</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-12">
                <div
                  className={index < ContentSharedTile.length - 1 ? "mb-2" : ""}
                >
                  <div className="mt-3">
                    <label>Shares:</label>
                    <span className="ms-3" style={{ color: "#3cd2a5" }}>
                      {product.count}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>,
  ];

  useEffect(() => {
    const endTimestamp = timestampInSeconds;
    const startTimestamp = endTimestamp - 7 * 24 * 60 * 60; // 7 days in seconds
    const defaultSelectedOption = dateOptions[1]; // Weekly by default

    setContentSelectedDateOption(defaultSelectedOption);
    setContentStartDate(new Date(startTimestamp * 1000)); // Convert seconds to milliseconds
    setContentEndDate(new Date(endTimestamp * 1000)); // Convert seconds to milliseconds

    ContentTileInfo(getFilterValue(currentTab), startTimestamp, endTimestamp);
  }, [currentTab]);

  const dateToTimestampInSeconds = (date) => {
    return Math.floor(date.getTime() / 1000);
  };

  const handleDateOptionChangeContent = (selectedOption) => {
    setContentSelectedDateOption(selectedOption);
    if (selectedOption.value === 3) {
      setContentCustomDateRange(true);
    } else {
      setContentCustomDateRange(false);
      const endTimestamp = timestampInSeconds;
      let startTimestamp;
      switch (selectedOption.value) {
        case 5: // Daily
          startTimestamp = endTimestamp - 24 * 60 * 60; // 1 day in seconds
          break;
        case 2: // Weekly
          startTimestamp = endTimestamp - 7 * 24 * 60 * 60; // 7 days in seconds
          break;
        case 1: // Monthly
          // Calculate start time for the beginning of the current month
          const currentDate = new Date();
          startTimestamp =
            new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              1
            ).getTime() / 1000;
          break;
        case 0: // Yearly
          // Calculate start time for the beginning of the current year
          startTimestamp =
            new Date(new Date().getFullYear(), 0, 1).getTime() / 1000;
          break;
        default:
          // Custom date range
          return;
      }

      setContentStartDate(new Date(startTimestamp * 1000)); // Convert seconds to milliseconds
      setContentEndDate(new Date(endTimestamp * 1000)); // Convert seconds to milliseconds
      ContentTileInfo(getFilterValue(currentTab), startTimestamp, endTimestamp);
    }
  };

  const handleCustomDateRangeChange = (startDate, endDate) => {
    const startTimestamp = dateToTimestampInSeconds(startDate);
    const endTimestamp = dateToTimestampInSeconds(endDate);
    ContentTileInfo(getFilterValue(currentTab), startTimestamp, endTimestamp);
  };

  const ContentTileInfo = async (
    filterValue,
    startTime = null,
    endTime = null,
    table = 0
  ) => {
    setIsLoading(true);
    try {
      if (!startTime || !endTime) {
        // Custom Date Range
        startTime = dateToTimestampInSeconds(ContentstartDate);
        endTime = dateToTimestampInSeconds(ContentendDate);
      }
      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/contentpageinfo?startTime=${startTime}&endTime=${endTime}&filter=${filterValue}&table=${table}`
      );
      const result = await response.json();
      switch (filterValue) {
        case 0:
          setContentViewTile(result);
          break;
        case 1:
          setContentClickedTile(result);
          break;
        case 2:
          setContentSharedTile(result);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Ensures loading is turned off whether fetch succeeds or fails
    }
  };

  const getFilterValue = (tab) => {
    switch (tab) {
      case "viewed":
        return 0;
      case "clicked":
        return 1;
      case "shared":
        return 2;
      default:
        return 0;
    }
  };

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
    setSelectedTable(0);
    ContentTileInfo(getFilterValue(tab));
    setContentCustomDateRange(false);
  };

  const handleTableChange = (event) => {
    setSelectedTable(parseInt(event.target.value));
    // Make API call with the selected table value
    ContentTileInfo(
      getFilterValue(currentTab),
      undefined,
      undefined,
      parseInt(event.target.value)
    );
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const getCurrentItems = () => {
    switch (currentTab) {
      case "viewed":
        return ContentviewcurrentItems;
      case "clicked":
        return ContentclickedcurrentItems;
      case "shared":
        return ContentsharedcurrentItems;
      default:
        return [];
    }
  };

  const getTotalItems = () => {
    switch (currentTab) {
      case "viewed":
        return getFilteredItems(ContentViewTile).length;
      case "clicked":
        return getFilteredItems(ContentClickedTile).length;
      case "shared":
        return getFilteredItems(ContentSharedTile).length;
      default:
        return 0;
    }
  };

  const getCurrentRange = () => {
    const start = (currentPage - 1) * itemsPerPage + 1;
    const end = Math.min(currentPage * itemsPerPage, getTotalItems());
    return `${start}-${end}`;
  };

  return (
    <>
      <div>
        <h6>
          <span style={{ color: "#3cd2a5" }}>PAGES</span> / CONTENT
        </h6>
        <div className="row">
          <div className="col-xl-8">
            <h1>CONTENT</h1>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10 pt-3">
                <ul className="nav nav-underline" id="myTab" role="tablist">
                  {producttabs.map((tab, index) => (
                    <li className="nav-item" role="presentation" key={index}>
                      <button
                        className={`nav-link ${tab.active ? "active" : ""}`}
                        id={`${tab.id}-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#${tab.id}`}
                        type="button"
                        role="tab"
                        aria-controls={tab.id}
                        aria-selected={tab.active ? "true" : "false"}
                        onClick={() => handleTabChange(tab.id)}
                      >
                        {tab.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-8">
          <div className="row">
            <div className="col-md-3 --bs-theme">
              <label htmlFor="date">Date</label>
              <Select
                options={dateOptions}
                value={ContentselectedDateOption}
                onChange={handleDateOptionChangeContent}
                classNamePrefix="react-select"
              />
              <div className="col-xl-9">
                {ContentcustomDateRange && (
                  <>
                    <label>From:</label>
                    <DatePicker
                      selected={ContentstartDate}
                      onChange={(date) => {
                        setContentStartDate(date);
                        handleCustomDateRangeChange(date, ContentendDate);
                      }}
                      className="form-control"
                    />
                    <br />
                    <label>To:</label>
                    <DatePicker
                      selected={ContentendDate}
                      onChange={(date) => {
                        setContentEndDate(date);
                        handleCustomDateRangeChange(ContentstartDate, date);
                      }}
                      className="form-control"
                    />
                  </>
                )}
              </div>
            </div>
            <div className="col-md-3 mt-3 pt-1">
              <Form.Control
                type="text"
                value={searchTerm} // Bind search term to input
                onChange={handleSearchChange} // Add search change handler
                placeholder="Search"
              />
            </div>

            <div className="col-md-4 mt-3 pt-1">
              <button className="btn btn-secondary">
                <i className="fas fa-search"></i>
              </button>
              &nbsp; &nbsp;
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="table"
                  id="table-0"
                  value={0}
                  checked={selectedTable === 0}
                  onChange={handleTableChange}
                />
                <label className="form-check-label" htmlFor="table-0">
                  Blogs/News
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="table"
                  id="table-1"
                  value={1}
                  checked={selectedTable === 1}
                  onChange={handleTableChange}
                />
                <label className="form-check-label" htmlFor="table-1">
                  Mixlist
                </label>
              </div>
            </div>
            <div className="col-md-2 mt-4 ">
              <p className="float-end">
                {getCurrentRange()} of {getTotalItems()}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-12">
        <Card className="mt-3">
          <CardBody>
            <div className="tab-content" id="myTabContent">
              {producttabs.map((tab, index) => (
                <div
                  className={`tab-pane fade ${tab.active ? "show active" : ""}`}
                  id={tab.id}
                  role="tabpanel"
                  aria-labelledby={`${tab.id}-tab`}
                  key={index}
                >
                  {tabContent[index]}
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
        <div className="row mt-3">
          <div className="col-md-10"></div>
          <div className="col-md-2">
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={getTotalItems()}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
